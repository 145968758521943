<template>
  <div class="customer">
    <div class="customer-title">
      <span>智能客服快速解疑</span>
      <img src="@/assets/common/close.png" alt="" @click="closeCustomer()" />
    </div>
    <div class="customer-content" id="chatBox">
      <div class="left">
        <img src="@/assets/common/customer.png" alt="" />
        <div>
          <div class="time">
            <span>智能客服</span>
            <span>18:34:50</span>
          </div>
          <div class="question">
            <div class="text">
              亲，您好，欢迎使用聚梦创新快速咨询问答，您想了解哪方面的问题呢？
            </div>
            <div class="text">猜您想问:</div>
            <ul>
              <li @click="answer(1)">1、解决方案方面</li>
              <li @click="answer(2)">2、区块链技术方面</li>
            </ul>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in answerArr" :key="index">
        <div class="right">
          <div class="time">
            <span>您</span>
            <span>18:40:34</span>
          </div>
          <div class="answer">{{ item.question }}</div>
        </div>
        <div class="left">
          <img src="@/assets/common/customer.png" alt="" />
          <div>
            <div class="time">
              <span>智能客服</span>
              <span>18:34:50</span>
            </div>
            <div class="question">
              <div class="text">
                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      answerArr: [],
    };
  },
  methods: {
    closeCustomer() {
      this.$emit("closeDialog", false);
    },
    answer(id) {
      if (id == 1) {
        this.answerArr.push({
          question: "1、解决方案方面",
          answer:
            "1、解决方案方面1、解决方案方面1、解决方案方面1、解决方案方面1、解决方案方面1、解决方案方面",
        });
      } else if (id == 2) {
        this.answerArr.push({
          question: "2、区块链技术方面",
          answer:
            "2、区块链技术方面2、区块链技术方面2、区块链技术方面2、区块链技术方面2、区块链技术方面2、区块链技术方面2、区块链技术方面",
        });
      }
      this.$nextTick(() => {
        var scrollDom = document.getElementById("chatBox");
        var aa = scrollDom.scrollHeight
        scrollDom.scrollTop = scrollDom.scrollHeight;
        // console.log(aa,'aa')
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 20;
  &-title {
    width: 500px;
    height: 60px;
    background: #3d5dcc;
    border-radius: 4px 4px 0px 0px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    box-sizing: border-box;
    img {
      cursor: pointer;
    }
  }
  &-content {
    width: 500px;
    height: 380px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(29, 44, 103, 0.16);
    border-radius: 0px;
    overflow: auto;
    padding: 30px 20px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      margin-bottom: 20px;
      img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
      .time {
        span {
          margin-right: 8px;
        }
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: rgba(29, 44, 103, 0.6);
      }

      .question {
        width: 252px;
        background: #f2f2fa;
        box-shadow: 0px 1px 4px rgba(29, 44, 103, 0.16);
        border-radius: 0px 8px 8px 8px;
        padding: 14px;
        box-sizing: border-box;
        margin-top: 8px;
        .text {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #1d2c67;
          margin-bottom: 8px;
          padding-right: 12px;
          box-sizing: border-box;
        }
        ul {
          li {
            list-style: none;
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #3d5dcc;
            cursor: pointer;
          }
          li:hover {
            color: #344fad;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .time {
        span {
          margin-left: 8px;
        }
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: rgba(29, 44, 103, 0.6);
      }
      .answer {
        width: 134px;
        background: #3d5dcc;
        box-shadow: 0px 1px 4px rgba(29, 44, 103, 0.16);
        border-radius: 8px 0px 8px 8px;
        padding: 10px 14px;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #ffffff;
        box-sizing: border-box;
        margin-top: 8px;
      }
    }
  }
}
</style>