<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePc :programmeArr="programmeArr"></ProgrammePc>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <Programme :programmeArr="programmeArr"></Programme>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import Programme from "./modules/Programme.vue";
import ProgrammePc from "./modules/ProgrammePc.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    Programme,
    ProgrammePc,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 文娱IP",
        desc: " 通过将IP资产数字化，打造IP交易、周边开发、IP制作、发行分销、品牌运营等全流程IP生态制造，为文化、旅游、影视、游戏等众多潜在IP生态领域提供全流程解决方案。  ",
        imgUrl: require("@/assets/solution/entertainment/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 文娱IP",
        desc: " 通过将IP资产数字化，打造IP交易、周边开发、IP制作、发行分销、品牌运营等全流程IP生态制造，为文化、旅游、影视、游戏等众多潜在IP生态领域提供全流程解决方案。  ",
        imgUrl: require("@/assets/solution/entertainment/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "版权方维权困难",
          desc: "创作者的原创力和精力都是有限的，文娱产业的保鲜期一般比较短，更新速度快，好的文学作品产量低而且周期长，而且盗版严重、维权困难。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "版权方无收入",
          desc: "编辑写的剧本没有获得收入，歌手录制的歌曲结果被盗版，连正在上映的电影，都有各种片段和素材流出。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "发行管控",
          desc: "创作者经由第三方平台发售后，传统中介会对应扣取部分费用，创作者无法控制价格。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/entertainment/entertainment-icon-1.png"),
            desc: "版权归属明确",
          },
          {
            imgUrl: require("@/assets/solution/entertainment/entertainment-icon-2.png"),
            desc: "环节衔接高效",
          },
          {
            imgUrl: require("@/assets/solution/entertainment/entertainment-icon-3.png"),
            desc: "品牌打造快捷",
          },
          {
            imgUrl: require("@/assets/solution/entertainment/entertainment-icon-4.png"),
            desc: "生态管理完善",
          },
        ],
        title: "通过IP资产数字化，助力文化娱乐IP产业生态发展",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/entertainment/entertainment.png"),
          name: "IP开发",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>