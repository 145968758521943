<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePc :programmeArr="programmeArr"></ProgrammePc>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <Programme :programmeArr="programmeArr"></Programme>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import Programme from "./modules/Programme.vue";
import ProgrammePc from "./modules/ProgrammePc.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    Programme,
    ProgrammePc,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 农业",
        desc: " 通过农产品防伪、物流追踪、商品流转存证、可信监管检测等多种业务组合，塑造农业生态品牌，减少农产品流通成本，助力智慧农业发展",
        imgUrl: require("@/assets/solution/agriculture/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 农业",
        desc: " 通过农产品防伪、物流追踪、商品流转存证、可信监管检测等多种业务组合，塑造农业生态品牌，减少农产品流通成本，助力智慧农业发展",
        imgUrl: require("@/assets/solution/agriculture/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "农产品安全存疑",
          desc: " 农产品的质量问题都无法得到有效验证与保障。在复杂的生产-销售链条中，鱼龙混杂的农产品不断消耗消费者的信任与耐心。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "数据溯源不可信",
          desc: "传统模式溯源数据易篡改、造假成本低，消费者对溯源内容真实性多有质疑，不仅无法为信誉好、质量优的产品保驾护航，而且导致溯源服务沦为鸡肋。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "传统农业物联网成本过大",
          desc: "目前农业物联网应用成本和维护成本过高、性能差。而且物联网是中心化管理，随着物联网设备的暴增，数据中心的基础设施投入与维护成本难以估量。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/agriculture/agriculture-icon-1.png"),
            desc: "可信防伪溯源",
          },
          {
            imgUrl: require("@/assets/solution/agriculture/agriculture-icon-2.png"),
            desc: "优化产品分销",
          },
          {
            imgUrl: require("@/assets/solution/agriculture/agriculture-icon-3.png"),
            desc: "创建可信品牌",
          },
          {
            imgUrl: require("@/assets/solution/agriculture/agriculture-icon-4.png"),
            desc: "管理监督高效",
          },
        ],
        title:
          "整合生产、监管、物流、批发零售等各方的资源和需求，建设智慧农业生态",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/agriculture/agriculture.png"),
          name: "农产品防伪溯源",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>