<template>
  <div class="open-btn" @click="goUrl('/contact')">联系我们</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.open-btn {
  font-family: "Helvetica Neue";
  font-style: normal;
  width: 140px;
  height: 44px;
  background: #3d5dcc;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 44px;
  color: #ffffff;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
}
.open-btn:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #1a61ea;
}
</style>