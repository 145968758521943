<template>
  <div class="programme">
    <div class="programme-title wow slideInUp animate__animated_title">
      方案价值
    </div>
    <div class="subheading-title wow slideInUp animate__animated_title">
      {{ programmeArr.title }}
    </div>
    <div class="icon-box wow slideInUp animate__animated_title">
      <div
        class="icon-wrap"
        v-for="(item, index) in programmeArr.iconArr"
        :key="index"
      >
        <div class="icon">
          <img :src="item.imgUrl" />
          <div class="desc" v-html="item.desc"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  props: {
    programmeArr: Object,
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.programme {
  width: 100%;
  background: url("../../../assets/mobile/skew-bg.png") no-repeat 100% 100%;
  background-position: left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0 52px;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
  }
  .subheading-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: rgba(29, 44, 103, 0.8);
    margin: 12px 0 5px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    text-align: center;
  }
  .icon-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    .icon-wrap {
      width: 50%;
      box-sizing: border-box;
      .icon {
        width: 100%;
        height: 130px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;
        img {
          width: 45px;
          height: 45px;
          margin: 0 auto;
          display: block;
        }
        .desc {
          font-family: "Helvetica Neue";
          font-style: normal;
          // font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: #15255b;
          margin-top: 16px;
        }
      }
    }
    .icon-wrap:nth-child(2n + 1) {
      padding-right: 7.5px;
    }
    .icon-wrap:nth-child(2n) {
      padding-left: 7.5px;
    }
  }
}
</style>