<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePc :programmeArr="programmeArr"></ProgrammePc>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <Programme :programmeArr="programmeArr"></Programme>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import Programme from "./modules/Programme.vue";
import ProgrammePc from "./modules/ProgrammePc.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    Programme,
    ProgrammePc,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 政务",
        desc: "为跨地区、跨部门协作提供可靠的数据共享通道，为大众提供透明可信的政务公开平台。  ",
        imgUrl: require("@/assets/solution/government/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 政务",
        desc: "为跨地区、跨部门协作提供可靠的数据共享通道，为大众提供透明可信的政务公开平台。  ",
        imgUrl: require("@/assets/solution/government/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "存在“数据孤岛”",
          desc: "政府部门之间共享信息往往过程复杂、流程繁复、延时性高。且机构间数据交换时无法确保信息未被篡改",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "协作机制不完善",
          desc: "政府部门工作开展过程，各机关在处理案件过程中，先多为线下协作，易发生不合规、不到位等情况，导致数据缺乏同步",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "身份难以确认",
          desc: "身份认证体系不同，在数据交换、业务协作时无法相验身份，导致线上协作共享难",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/government/government-icon-1.png"),
            desc: "部门协作高效",
          },
          {
            imgUrl: require("@/assets/solution/government/government-icon-2.png"),
            desc: "政务公开透明",
          },
          {
            imgUrl: require("@/assets/solution/government/government-icon-3.png"),
            desc: "公众服务便捷",
          },
          {
            imgUrl: require("@/assets/solution/government/government-icon-4.png"),
            desc: "行政管理精细",
          },
        ],
        title: "提高服务效率，精细行政管理",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/government/government.png"),
          name: "政府链",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>