<template>
  <div style="height: 100%">
    <Nav v-if="!mobile"></Nav>
    <NavMobile v-if="mobile"></NavMobile>
    <router-view></router-view>
    <Footer style="min-width:1280px" v-if="!mobile"></Footer>
    <FooterMobile v-if="mobile"></FooterMobile>
    <Suspension  v-if="!mobile"></Suspension>
    <!-- <Customer></Customer> -->
  </div>
</template>

<script>
import Nav from "../../components/common/Nav.vue";
import NavMobile from "../../components/common/NavMobile.vue";
import Footer from "../../components/common/Footer.vue";
import FooterMobile from "../../components/common/FooterMobile.vue";
import Suspension from "../../components/commonPopup/Suspension.vue";
import Customer from "../../components/commonPopup/Customer.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Footer,
    FooterMobile,
    Nav,
    NavMobile,
    Suspension,
    Customer,
  },
  data() {
    return {
      mobile: false,
      tablet: false,
    };
  },

  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType123", deviceType);
      // 媒体改变时 将新的状态传给store
      if (deviceType == "mobile") {
        this.mobile = true;
        this.tablet = false;
      } else if (deviceType == "tablet") {
         this.mobile = true;
        this.tablet = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style>
</style>