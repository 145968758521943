<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePcOther :programmeArr="programmeArr"></ProgrammePcOther>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <ProgrammeOther :programmeArr="programmeArr"></ProgrammeOther>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import ProgrammeOther from "./modules/ProgrammeOther.vue";
import ProgrammePcOther from "./modules/ProgrammePcOther.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    ProgrammeOther,
    ProgrammePcOther,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 域名服务",
        desc: " 基于区块链的分布式、开放、和扩展的域名服务",
        imgUrl: require("@/assets/solution/ens/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 域名服务",
        desc: " 基于区块链的分布式、开放、和扩展的域名服务",
        imgUrl: require("@/assets/solution/ens/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "传统域名市场饱和",
          desc: "全球域名注册市场规模约过多，优质域名早已经被瓜分完毕，后来者难以注册并拥有优质的域名，只能通过高价购买获得。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "传统域名注册繁琐",
          desc: "传统域名由注册局进行管理，用户通过中介机构（域名注册服务商）进行注册，若是域名未及时续费或是出现其他情况，域名则可能会被收回。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "域名管理过于中心化",
          desc: "在现有的DNS中，顶级域名的产生、分配完全依赖于ICANN，缺失公平竞争的机制，同时域名交易，变更手续繁琐，这也导致域名的管理效率低下。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/ens/ens-icon-1.png"),
            desc: "区块链域名系统的优质域名暂未被抢注多少，购买费用极低，且实现了“一次购买，终身使用”，用户可以无限注册域名进行转售。",
            title: "打开域名市场",
          },
          {
            imgUrl: require("@/assets/solution/ens/ens-icon-2.png"),
            desc: "智能合约可以取代多数注册机构的角色及流程。任何机构与个人可以依据注册机构的规则来创建子域名。而且是一次购买，终身使用。",
            title: "打破繁琐流程",
          },
          {
            imgUrl: require("@/assets/solution/ens/ens-icon-3.png"),
            desc: "区块链域名所有者安全性、隐私、所有权不受侵犯。同时可突破围墙/平台对域名的拦截和屏蔽，防止任何非授权的网络设备、浏览器和其它软件窃取隐私记录。",
            title: "隐私安全保障",
          },
          {
            imgUrl: require("@/assets/solution/ens/ens-icon-4.png"),
            desc: "区块链域名完成注册后，域名持有人能够直接控制域名，不需要担心域名会被托管中心删除或者出现无法进行域名转让的情况。",
            title: "域名去中心化",
          },
        ],
        title: "打破传统域名市场，Web 3.0 域名满足用户新需求",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/ens/ens.png"),
          name: "区块链域名",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
      if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>