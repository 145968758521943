<template>
  <div class="about">
    <AboutMobile v-if="mobile"></AboutMobile>
    <AboutPC v-if="!mobile" style="min-width:1280px"></AboutPC>
  </div>
</template>

<script>
import AboutMobile from "./modules/AboutMobile.vue";
import AboutPC from "./modules/AboutPC.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    AboutMobile,
    AboutPC,
  },
  data() {
    return {
      mobile: false,
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
      if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>