<template>
  <div class="solve">
   <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePcOther :programmeArr="programmeArr"></ProgrammePcOther>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <ProgrammeOther :programmeArr="programmeArr"></ProgrammeOther>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import ProgrammeOther from "./modules/ProgrammeOther.vue";
import ProgrammePcOther from "./modules/ProgrammePcOther.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    ProgrammeOther,
    ProgrammePcOther,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 数字藏品",
        desc: "NFT可作为一切非同质化资产的记账手段，标记了数字资产的唯一性和所有权关系。基于区块链的特定资产权益证明，推动数字资产私有化，实现数字资产的确权、发行、流转全生命周期管理。",
        imgUrl: require("@/assets/solution/nft/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 数字藏品",
        desc: "NFT可作为一切非同质化资产的记账手段，标记了数字资产的唯一性和所有权关系。基于区块链的特定资产权益证明，推动数字资产私有化，实现数字资产的确权、发行、流转全生命周期管理。",
        imgUrl: require("@/assets/solution/nft/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "数藏活动安全存疑",
          desc: "在数字藏品活动期间存在恶意刷取优惠福利的行为，并且流量突发容易遭受DDoS攻击，黑客容易获取网站核心数据和用户隐私数据，导致数据被篡改、被删除等风险。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "数藏系统体验差",
          desc: "数字资产主要以数字图片，3D模型，视频等形式为主，由于藏品从十几KB到几MB不等，大量发行时需要大量存储空间，用户通过APP浏览或者下载藏品时，会出现卡顿，影响体验。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "数藏业务高并发",
          desc: "数字资产大多情况都是限时发售，在抢购数字资产时，由于大量玩家涌入，业务高并发，常常会出现应用无法访问，无法支付等崩溃的情况。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/nft/nft-icon-1.png"),
            desc: "实名认证访问，并通过DDoS高防防大流量攻击，提升访问安全",
            title: "安全应用",
          },
          {
            imgUrl: require("@/assets/solution/nft/nft-icon-2.png"),
            desc: "CDN加速图片视频访问，提供稳定、优质的下载加速服务",
            title: "增强用户体验",
          },
          {
            imgUrl: require("@/assets/solution/nft/nft-icon-3.png"),
            desc: "部署高并发系统架构，应对秒杀等高并发场景",
            title: "高并发架构",
          },
          {
            imgUrl: require("@/assets/solution/nft/nft-icon-4.png"),
            desc: "仅需前端交易平台API对接服务即可快速完成数字内容交易平台开发，实现数字内容全生命周期管理",
            title: "简单易用",
          },
        ],
        title: "用户自由定制构，建业务场景",
      },
      frameworkArr: [
         {
          imgUrl: require("@/assets/solution/nft/nft.png"),
          name: "NFT 数字藏品",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>