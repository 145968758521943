<template>
  <div class="core">
    <Core v-if="mobile" :coreArr="coreArr"></Core>
    <CorePc v-if="!mobile" style="min-width:1280px" :coreArr="coreArr"></CorePc>
  </div>
</template>

<script>
import Core from "./modules/Core.vue";
import CorePc from "./modules/CorePc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: { Core, CorePc },
  data() {
    return {
      mobile: false,
      coreArr: {
        bannerImage: require("@/assets/core/sealabc/sealabc-banner.png"),
        bannerTitle: "SealSC-SealABC",
        bannerDesc:
          "高度灵活模块化的区块链系统开发框架，助力传统产业快速实现数字化转型。",
        introduceTitle: "组件化积木式搭建方式",
        introduceImage: require("@/assets/core/sealabc/introduce-info.png"),
        introduceDesc:
          "SealABC将区块链拆解为网络组件、共识组件、虚拟机组件、存储组件、应用组件，以及配套的工具集合。为不同组件制定了标准交互接口，并提供了这些组件的相应的实现，让开发者可以直接使用SealABC框架提供的功能实现，快速搭建一条高性能区块链系统。",
        trialStatus: 2,
        technologyArr: [
          {
            imgUrl: require("@/assets/core/sealabc/platform-icon1.png"),
            title: "高性能系统",
            desc: "高速共识算法，硬件密码学组件，最高性能可达 10000+ TPS",
          },
          {
            imgUrl: require("@/assets/core/sealabc/platform-icon2.png"),
            title: "组件化部署",
            desc: "积木式搭建区块链系统，更加贴合实际业务需求",
          },
          {
            imgUrl: require("@/assets/core/sealabc/platform-icon3.png"),
            title: "全景可视化操作",
            desc: "可视化合约部署、链上交互、运维监控，让区块链系统使用更简洁",
          },
          {
            imgUrl: require("@/assets/core/sealabc/platform-icon4.png"),
            title: "数据隐私安全",
            desc: "多模式数据脱敏存储，支持国密SM2/3/4以及国际通用密码学算法",
          },
        ],
        systemImage: require("@/assets/core/sealabc/products-SealABC.png"),
        productsArr: [
          {
            imgUrl: require("@/assets/core/sealabc/major-icon.png"),
            desc: "标准功能集成",
          },
          {
            imgUrl: require("@/assets/core/sealabc/reliable-icon.png"),
            desc: "多种底层框架",
          },
          {
            imgUrl: require("@/assets/core/sealabc/flexible-icon.png"),
            desc: "场景适配灵活",
          },
          {
            imgUrl: require("@/assets/core/sealabc/comprehensive-icon.png"),
            desc: "敏捷开发套件",
          },
        ],
      },
    };
  },
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>