<template>
  <div>
    <div class="banner">
      <div class="header-image">
        <img src="@/assets/mobile/banner-bg.png" alt="" />
        <div class="banner-image">
          <img src="@/assets/home/home-banner.png" alt="" />
        </div>
      </div>
      <div class="banner-box">
        <div class="banner-title">
          聚集梦想 & 创新未来 <br />致力于区块链+ 领域的开拓与创新
        </div>
        <div class="banner-desc">
          聚梦创新为企业及开发者提供高性能、高可用和高安全的区块链技术服务，帮助企业和开发人员快速、低成本的创建、部署、管理和使用区块链技术。
        </div>
      </div>
    </div>
    <div class="home-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img src="@/assets/home/home-banner.png" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">
              聚集梦想 & 创新未来 <br />致力于区块链+ 领域的开拓与创新
            </div>
            <div class="content">
              聚梦创新为企业及开发者提供高性能、高可用和高安全的区块链技术服务，帮助企业和开发人员快速、低成本的创建、部署、管理和使用区块链技术。
            </div>
            <!-- <div style="display: flex">
            <div class="open-btn">探索解决方案</div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="technology wow slideInUp animate__animated_title">
      <div class="technology-title">自研创新的区块链+产品</div>
      <div class="icon-box">
        <div class="icon" v-for="(item, index) in innovateArr" :key="index" @click="goUrl(item.path)">
          <div>
            <img :src="item.img" alt="" />
          </div>
          <div>
            <div class="title">{{ item.title }}</div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce wow slideInUp animate__animated_title">
      <div class="introduce-title">助力各行业价值互联网解决方案</div>
      <div class="introduce-box">
        <div
          class="tab"
          v-for="item in tabArr"
          :key="item.id"
          :class="tabIndex == item.id && showSolve ? 'highlight' : ''"
        >
          <div class="tab-title" @click="tabChange(item.id)">
            <span>{{ item.name }}</span>
            <img
              src="@/assets/mobile/xiala 24.png"
              alt=""
              v-if="tabIndex == item.id && showSolve"
            />
            <img src="@/assets/mobile/xiala 21.png" alt="" v-else />
          </div>
          <div class="tab-content">
            <div class="text-box">
              <div class="ques-title">行业问题</div>
              <div class="ques-desc">{{ item.questionDesc }}</div>
              <div class="ques-title">解决方案</div>
              <div class="ques-desc">{{ item.answerDesc }}</div>
            </div>
            <div class="ques-title">行业痛点</div>
            <div class="icon-box">
              <div class="icon-desc" v-for="ele in item.iconArr" :key="ele.id">
                <img :src="ele.imgUrl" alt="" />
                <div class="text">{{ ele.desc }}</div>
              </div>
            </div>
            <div class="detail-btn" @click="goUrl(item.path)">了解方案详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="products wow slideInUp animate__animated_title">
      <div class="products-title">多样化区块链服务</div>
      <div class="icon-mobile">
        <div class="icon" v-for="(item, index) in diversifyArr" :key="index">
          <img :src="item.imgUrl" alt="" />
          <div class="icon-box">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <div class="icon-ipad">
        <div
          class="icon"
          v-for="(item, index) in diversifyArr"
          :key="index"
          :style="{
            'background-image': 'url(' + item.imgBgUrl + ')',
            'background-repeat': 'no-repeat',

            'background-size': 'cover',
            'background-position': 'right bottom',
          }"
        >
          <div class="icon-box">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
      <div class="icon-ipad-1">
        <div
          class="icon"
          v-for="(item, index) in diversifyArr"
          :key="index"
          :style="{
            'background-image': 'url(' + item.imgBgUrl_1 + ')',
            'background-repeat': 'no-repeat',

            'background-size': 'cover',
            'background-position': 'right bottom',
          }"
        >
          <div class="icon-box">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="home-partners">
      <div class="home-partners-title">合作品牌</div>
      <div class="home-partners-box">
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
      </div>
    </div> -->

    <div class="service">
      <div class="title wow slideInUp animate__animated_bg">
        立即免费体验区块链应用
      </div>
      <div class="desc wow slideInUp animate__animated_bg">
        还有问题？请联系我们获取更多信息
      </div>
      <div class="ipad-btn wow slideInUp animate__animated_bg">
        <div class="service-btn" @click="goUrl('/contact')">合作咨询</div>
        <!-- <div class="contact-btn" @click="customerShow = true">快速解疑</div> -->
        <!-- <div class="border-btn">前往论坛</div> -->
      </div>
    </div>
    <Customer v-show="customerShow" @closeDialog="closeDialog"></Customer>
  </div>
</template>

<script>
import Customer from "../../../components/commonPopup/Customer.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Customer,
  },
  data() {
    return {
      customerShow: false,
      activeName: "first",
      show: true,
      current: 0,
      diversifyArr: [
        {
          imgUrl: require("@/assets/mobile/sence-bg.png"),
          imgBgUrl: require("@/assets/mobile/sence-bg-ipad.png"),
          imgBgUrl_1: require("@/assets/mobile/sence-bg-ipad-1.png"),
          title: "场景融合",
          desc: "结合各个行业具体业务场景，以区块链技术为基础，通过XaaS服务、人工智能、大数据分析等技术，为供应链金融、供应链溯源、版权交易、数据存证、财务审计等各个场景提供全面的区块链技术接入。",
        },
        {
          imgUrl: require("@/assets/mobile/safe-bg.png"),
          imgBgUrl: require("@/assets/mobile/safe-bg-ipad.png"),
          imgBgUrl_1: require("@/assets/mobile/safe-bg-ipad-1.png"),
          title: "高度安全",
          desc: "使用高度安全的网络传输、数据存储技术，提供丰富的密码学工具，支持区块链网络分级保护、全网监控、多方治理、实时告警等功能强大的运维系统，让企业数据得到全方位的安全保护。",
        },
        {
          imgUrl: require("@/assets/mobile/flexible-bg.png"),
          imgBgUrl: require("@/assets/mobile/flexible-bg-ipad.png"),
          imgBgUrl_1: require("@/assets/mobile/flexible-bg-ipad-1.png"),
          title: "性能优越",
          desc: "在满足安全性的前提下，通过高速共识算法，P2P网络优化，交易流程设计，高性能密码学工具等多领域技术的支撑，提供高达10000+ TPS的交易速度，满足企业高交易吞吐量业务的需求。",
        },
        {
          imgUrl: require("@/assets/mobile/superior-bg.png"),
          imgBgUrl: require("@/assets/mobile/superior-bg-ipad.png"),
          imgBgUrl_1: require("@/assets/mobile/superior-bg-ipad-1.png"),
          title: "扩展灵活",
          desc: "模块化可插拔的系统架构，标准模板化的业务搭建，天然内置的跨链特性，安全可靠的预言机网络，让企业可以根据需求灵活的调整区块链技术在业务中的应用，随时拓展新的业务领域，拓宽业务边界。",
        },
      ], //多样化区块链服务
      innovateId: null,
      innovateArr: [
        {
          img: require("@/assets/home/innovate/innovate-01.png"),
          imgActive: require("@/assets/home/innovate/innovate-01-active.png"),
          title: "XaaS云服务",
          desc: "围绕Web 3.0业务的一站式服务平台",
          path:'/xaas',
        },
        {
          img: require("@/assets/home/innovate/innovate-02.png"),
          imgActive: require("@/assets/home/innovate/innovate-02-active.png"),
          title: "SealABC",
          desc: "高速共识算法，硬件密码学组件，组件化积木式搭建方式",
          path:'/sealabc',
        },
      ], //自主创新
      senceArr: [
        {
          title: "政务",
          descList: ["政务公开透明", "部门协作高效", "数据安全共享"],
          img: require("@/assets/home/products/01.png"),
          imgActive: require("@/assets/home/products/01_selected.png"),
          id: 1,
        },
        {
          title: "金融",
          descList: ["价值传递安全", "流转清晰明了", "审计高效合规"],
          img: require("@/assets/home/products/02.png"),
          imgActive: require("@/assets/home/products/02_selected.png"),
          id: 2,
        },
        {
          title: "版权",
          descList: ["确权效率提高", "交易安全可靠", "维权有证可查"],
          img: require("@/assets/home/products/03.png"),
          imgActive: require("@/assets/home/products/03_selected.png"),
          id: 3,
        },
        {
          title: "农业",
          descList: ["产品防伪溯源", "物流效率提高", "分销环节优化"],
          img: require("@/assets/home/products/04.png"),
          imgActive: require("@/assets/home/products/04_selected.png"),
          id: 4,
        },
        {
          title: "医疗健康",
          descList: ["信息高度安全", "投保理赔高效", "医疗数据共享"],
          img: require("@/assets/home/products/05.png"),
          imgActive: require("@/assets/home/products/05_selected.png"),
          id: 5,
        },
        {
          title: "物联网",
          descList: ["网络安全增强", "隐私全面保护", "业务高度可靠"],
          id: 6,
          img: require("@/assets/home/products/06.png"),
          imgActive: require("@/assets/home/products/06_selected.png"),
        },
      ],
      senceId: null,
      tabPosition: "left",
      tabArr: [
        {
          id: 0,
          name: "NFT 数字藏品",
          questionDesc:
            "数字藏品活动期间存在恶意刷取优惠福利的行为，并且流量突发容易遭受DDoS攻击，黑客容易获取网站核心数据和用户隐私数据，导致数据被篡改、被删除等风险；且用户在抢购数字资产时，由于大量玩家涌入，业务高并发，常常会出现应用无法访问，无法支付等崩溃的情况。",
          answerDesc:
            "数字资产管理平台采用高可用、大并发的系统架构，通过DDoS高防防大流量攻击，提升访问安全，基于数字资产链，仅需API对接快速完成平台开发，实现数字内容全生命周期管理。",
          path: "/nft",
          iconArr: [
            {
              desc: "活动安全存疑",
              imgUrl: require("@/assets/home/introduce/icon-1.png"),
            },
            {
              desc: "系统体验差",
              imgUrl: require("@/assets/home/introduce/icon-2.png"),
            },
            {
              desc: "业务高并发",
              imgUrl: require("@/assets/home/introduce/icon-3.png"),
            },
          ],
        },
        {
          id: 1,
          name: "DID 身份认证",
          questionDesc:
            "互联网用户的身份的特征和行为暴露在网络中，被各个平台无视相关规定，肆意收集身份相关的行为信息并滥用这些信息；且不同网站自己用的身份系统（及账户对应的数据）之间是不互通的，不同的中心化网站上有一套自己的身份系统，每个都需要用户重新注册一个账户。",
          answerDesc:
            "利用区块链密码学等技术助力身份标识脱敏，避免敏感数据盗用。全链路加密和存储身份信息，使得数据使用可用不可见；DID可以在各种平台上运行，包括不同的区块链，利用分散身份协议的用户，可以安全地管理用户个人的数字身份，将其存储在手机或云服务上，并有一个私人密钥，只允许经过验证的用户访问。",
          path: "/did",
          iconArr: [
            {
              desc: "个人隐私保护难题",
              imgUrl: require("@/assets/home/introduce/icon-4.png"),
            },
            {
              desc: "中心化身份容错率低",
              imgUrl: require("@/assets/home/introduce/icon-5.png"),
            },
            {
              desc: "系统之间数据不互通",
              imgUrl: require("@/assets/home/introduce/icon-6.png"),
            },
          ],
        },
        {
          id: 2,
          name: "域名服务",
          questionDesc:
            "全球传统域名注册市场规模约过多，优质域名早已经被瓜分完毕，后来者难以注册并拥有优质的域名，只能通过高价购买获得；且域名由注册局进行管理，用户通过中介机构（域名注册服务商）进行注册，若是域名未及时续费或是出现其他情况，域名则可能会被收回。",
          answerDesc:
            "区块链域名系统的优质域名暂未被抢注多少，购买费用极低，且实现了“一次购买，终身使用”，用户可以无限注册域名进行转售；智能合约可以取代多数注册机构的角色及流程。任何机构与个人可以依据注册机构的规则来创建子域名。而且是一次购买，终身使用。",
          path: "/ens",
          iconArr: [
            {
              desc: "传统域名市场饱和",
              imgUrl: require("@/assets/home/introduce/icon-7.png"),
            },
            {
              desc: "传统域名注册频繁",
              imgUrl: require("@/assets/home/introduce/icon-8.png"),
            },
            {
              desc: "域名管理中心化",
              imgUrl: require("@/assets/home/introduce/icon-9.png"),
            },
          ],
        },
        {
          id: 3,
          name: "NFT 票务",
          questionDesc:
            "传统票务受限于监管管控，内票发行渠道较少，二级票务市场的转售商哄抬票价，黄牛票贩则通过网络买下大部分门票并高价转售，对渠道方以及购票用户带来不可估量的价值损失。",
          answerDesc:
            "门票存储与区块链上，任何人都能在区块链账本中追踪交易，活动组织方可以更好地控制一级和二级市场的门票出售；可借助智能合约这个运行于区块链的程序对转售价格和费用设限。智能合约是确定性程序，会在达到某些条件时自主执行特定任务。组织方通过设置规则，即可决定如何分配二级门票销售的版权费。只要门票出售给新的所有者，就会触发版权费支付。",
          path: "/ticketing",
          iconArr: [
            {
              desc: "票务发行受管控",
              imgUrl: require("@/assets/home/introduce/icon-10.png"),
            },
            {
              desc: "票务易伪造",
              imgUrl: require("@/assets/home/introduce/icon-11.png"),
            },
            {
              desc: "纸质票务易丢失",
              imgUrl: require("@/assets/home/introduce/icon-12.png"),
            },
          ],
        },
      ],
      tabIndex: 0,
      showSolve: true,
    };
  },
  methods: {
    closeDialog(value) {
      this.customerShow = value;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    removeActive($event, id) {
      this.current = id;
      this.show = true;
    },
    enter(index) {
      this.innovateId = index;
    },
    leave() {
      this.innovateId = null;
    },
    enterSence(id) {
      this.senceId = id;
    },
    leaveSence() {
      this.senceId = null;
    },
    goUrl(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
    tabChange(id) {
      this.tabIndex = id;
      this.showSolve = true;
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.banner {
  display: block;
  width: 100%;
  height: 480px;
  background: #f5f7fc;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 480px;
    img {
      width: 100%;
      height: 345px;
    }
    .banner-image {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .banner-box {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 30px;
    box-sizing: border-box;
    font-family: "Helvetica Neue";
    font-style: normal;
    .banner-title {
      font-weight: 700;
      font-size: 23px;
      line-height: 140%;
      text-align: center;
      width: 100%;
      color: #1d2c67;
      margin-top: 40px;
    }
    .banner-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 45px */
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(29, 44, 103, 0.8);
      margin-top: 20px;
    }
  }
}
.technology {
  width: 100%;
  padding: 40px 15px 32px;
  box-sizing: border-box;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
  }
  .icon-box {
    margin-top: 5px;
    width: 100%;
    .icon {
      width: 100%;
      height: 132px;
      display: flex;
      padding: 0 15px 0 15px;
      background: linear-gradient(90deg, #eef3ff 0%, #fafbff 100%);
      box-shadow: 0px 6px 10px rgba(29, 44, 103, 0.1);
      border-radius: 4px;
      box-sizing: border-box;
      align-items: center;
      margin-top: 15px;
      img {
        width: 55px;
        height: 55px;
        margin-right: 15px;
      }
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #1d2c67;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: rgba(29, 44, 103, 0.6);
        margin-top: 15px;
      }
    }
  }
}
.introduce {
  background: url("../../../assets/mobile/introduce-bg.png") no-repeat 100% 100%;
  background-position: left top;
  background-size: 100% 100%;
  padding: 70px 15px 50px;
  box-sizing: border-box;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  &-box {
    .highlight .tab-content {
      max-height: 500px !important;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 29px;
    }
    .tab {
      width: 100%;
      margin-bottom: 15px;
      background: rgba(46, 47, 52, 0.5);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 15px;
      box-sizing: border-box;
      .tab-title {
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff;

        border-radius: 4px;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .tab-content {
        overflow: hidden;
        max-height: 0;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        .text-box {
          // height: 290px;
        }
        .ques-title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
          margin: 20px 0 10px;
        }
        .ques-desc {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          color: rgba(255, 255, 255, 0.6);
        }
        .icon-box {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          .icon-desc {
            height: 62px;
            display: flex;
            align-items: center;
            flex-direction: column;
            img {
              width: 40px;
              height: 40px;
            }
            .text {
              margin-top: 7px;
              font-family: "Helvetica Neue";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
        .detail-btn {
          background: linear-gradient(
            90deg,
            #5c90fe 11.73%,
            #466cf5 56.21%,
            #4265f3 69.15%,
            #3853ef 87.49%,
            #2e42eb 100.05%
          );
          border-radius: 2px;
          width: 100%;
          height: 45px;
          line-height: 45px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #e8eaf4;
          margin-top: 30px;
        }
      }
    }
  }
}
.products {
  width: 100%;
  padding-bottom: 36px;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
    margin: 40px 0 20px;
  }
  .icon-mobile {
    display: block;
    .icon {
      position: relative;
      padding: 0 15px;
      box-sizing: border-box;
      width: 100%;
      .icon-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 20px 30px 27px;
        box-sizing: border-box;
        .desc {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.6);
          margin-top: 10px;
        }
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 17px;
          color: #1d2c67;
        }
      }
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .icon-ipad {
    display: none;
  }
  .icon-ipad-1 {
    display: none;
  }
}
.service {
  width: 100%;
  background: url("../../../assets/mobile/experience-bg.png") no-repeat 100%
    100%;
  background-position: left top;
  background-size: 100% 100%;
  padding: 60px 15px 34px;
  box-sizing: border-box;
  .title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .desc {
    font-family: "Helvetica Neue";
    font-style: normal;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    text-align: center;
    color: #ffffff;
  }
  .service-btn {
    width: 100%;
    height: 45px;
    background: #ffffff;
    border-radius: 2px;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #1d2c67;
    line-height: 45px;
    margin-top: 10px;
  }
  .border-btn {
    width: 100%;
    height: 45px;
    background: #ffffff;
    border-radius: 2px;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #1d2c67;
    line-height: 45px;
    margin-top: 10px;
  }
}
.active {
  opacity: 1;
}
.home-title {
  display: none;
}
@-webkit-keyframes menufirst {
  0% {
    height: 0px;
  }
  100% {
    height: 492px;
  }
}
@media screen and (min-width: 575px) and (max-width: 1180px) {
  .technology {
    .icon-box {
      display: flex;
    }
    .icon:first-child {
      margin-right: 15px;
    }
  }
  .service {
    background: url("../../../assets/mobile/experience-bg-ipad.png") no-repeat
      100% 100%;
    background-position: left top;
    background-size: 100% 100%;
    .ipad-btn {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .service-btn {
      width: 150px;
      height: 45px;
      line-height: 45px;
      margin-right: 15px;
      font-weight: 400;
      font-size: 14px;
      color: #3d5dcc;
    }
    .border-btn {
      width: 150px;
      height: 45px;
      line-height: 45px;
      background: transparent;
      box-sizing: border-box;
      border: 1px solid #ffffff;
      border-radius: 2px;
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .banner {
    display: none;
  }
  .home-title {
    display: block;
    width: 100%;
    height: 268px;
    background: rgba(245, 247, 252, 0.4);
    position: relative;
    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 25%);
      z-index: 0;
      height: 268px;
      img {
        width: 100%;
        height: 268px;
      }
      .right {
        position: absolute;
        top: 0;
        right: -25%;
        width: 50%;
        display: flex;
        align-items: center;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .banner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: calc(100vw - 25%);
      height: 268px;
      z-index: 2;
      background: transparent;
    }
    .banner-box {
      width: calc(100vw - 25%);
      height: 268px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 25px;
          color: #1d2c67;
        }
        .content {
          width: 70%;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.8);
          margin-top: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 445px) and (max-width: 900px) {
  .products {
    .icon-mobile {
      display: none;
    }
    .icon-ipad-1 {
      display: none;
    }
    .icon-ipad {
      display: block;
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      box-sizing: border-box;
      .icon {
        width: 50%;
        padding: 15px 15px 40px;
        box-sizing: border-box;
        .icon-box {
          .desc {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: rgba(29, 44, 103, 0.6);
            margin-top: 10px;
          }
          .title {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #1d2c67;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 901px) and (max-width: 1180px) {
  .products {
    .icon-mobile {
      display: none;
    }
    .icon-ipad {
      display: none;
    }
    .icon-ipad-1 {
      display: block;
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      box-sizing: border-box;
      .icon {
        width: 50%;
        padding: 15px 15px 40px;
        box-sizing: border-box;
        .icon-box {
          .desc {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: rgba(29, 44, 103, 0.6);
            margin-top: 10px;
          }
          .title {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #1d2c67;
          }
        }
      }
    }
  }
}

.animate__animated {
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>