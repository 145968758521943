<template>
  <div class="solve-sence wow slideInUp animate__animated_title">
    <div class="solve-sence-title">场景痛点</div>
    <div class="solve-sence-box">
      <div v-for="(item, index) in senceArr" :key="index">
        <div class="line"></div>
        <div class="little-box">
          <img :src="item.imgUrl" alt="" />
          <div class="introduce">{{ item.title }}</div>
          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  props: {
    senceArr: Array,
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.solve-sence {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 140px 0 160px;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
  }
  &-box {
    width: 1180px;
    justify-content: space-between;
    display: flex;
    .little-box {
      width: 374px;
      height: 280px;
      background: #ffffff;
      border: 1px solid rgba(61, 93, 204, 0.1);
      box-shadow: 0px 0px 24px rgba(29, 44, 103, 0.06);
      border-radius: 0 0 4px 4px;
      border-top: 0;
      padding: 40px 40px 0;
      box-sizing: border-box;
      position: relative;
      img {
        position: absolute;
        top: 40px;
        left: 40px;
      }
      .introduce {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1d2c67;
        margin: 42px 0 20px;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: rgba(29, 44, 103, 0.6);
      }
    }
    .line {
      width: 374px;
      height: 10px;
      background-color: #3d5dcc;
      border-radius: 4px 4px 0 0;
    }
  }
}
</style>