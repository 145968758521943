<template>
  <div class="core">
    <div class="core-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img :src="coreArr.bannerImage" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">
              {{ coreArr.bannerTitle }}
            </div>
            <div class="content">
              {{ coreArr.bannerDesc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="core-introduce">
      <div class="core-introduce-box">
        <div class="left">
          <img :src="coreArr.introduceImage" alt="" />
        </div>
        <div class="right wow slideInUp animate__animated_title">
          <div class="title">{{ coreArr.introduceTitle }}</div>
          <div class="desc">
            {{ coreArr.introduceDesc }}
          </div>
          <div class="open-btn" v-if="coreArr.trialStatus == 1">
            <a
              style="color: #fff"
              href="https://xaas.dreamchains.cn/"
              target="_blank"
              >免费试用
            </a>
          </div>
          <div class="disable-btn" v-else>即将上线</div>
        </div>
      </div>
    </div>
    <div class="core-technology">
      <div class="core-technology-title wow slideInUp animate__animated_title">
        平台特点
      </div>
      <div class="icon-box wow slideInUp animate__animated_title">
        <div
          class="icon"
          v-for="(item, index) in coreArr.technologyArr"
          :key="index"
        >
          <div>
            <img :src="item.imgUrl" alt="" />
          </div>
          <div>
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="core-system wow slideInUp animate__animated_title">
      <div class="core-system-title">系统架构</div>
      <div class="core-system-box">
        <img :src="coreArr.systemImage" alt="" />
      </div>
    </div>
    <div class="core-products">
      <div class="core-products-title wow slideInUp animate__animated_title">
        产品优势
      </div>
      <div class="icon-box wow slideInUp animate__animated_title">
        <div
          class="icon"
          v-for="(item, index) in coreArr.productsArr"
          :key="index"
        >
          <img
            :src="item.imgUrl"
            alt=""
            :class="coreArr.trialStatus == 2 ? 'seal-img' : 'xaas-img'"
          />
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="core-info">
      <div class="icon-box wow slideInUp animate__animated_bg">
        <div class="icon">
          <div>
            <div class="title">区块链平台适用</div>
            <div class="desc">
              如果你对区块链平台感兴趣，我们提供全功能免费试用版本供你体验。
            </div>
            <div class="btn contact" v-if="coreArr.trialStatus == 1">
              <a
                style="color: #fff"
                href="https://xaas.dreamchains.cn/"
                target="_blank"
                >立即试用
              </a>
            </div>
            <div class="btn disable" v-else>即将上线</div>
          </div>
        </div>
        <div class="icon">
          <div>
            <div class="title">联系我们</div>
            <div class="desc">
              如果您对以上服务有需求，可以联系我们，我们会第一时间与您联系。
            </div>
          </div>
          <div class="btn contact" @click="goUrl('/contact')">联系我们</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  components: {},
  props: {
    coreArr: Object,
  },
  data() {
    return {};
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },

  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.core {
  .top {
    margin-top: 80px;
  }
  &-title {
    width: 100%;
    height: 536px;
    position: relative;
    background: rgba(245, 247, 252, 0.4);
    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 25%);
      z-index: 0;
      height: 536px;
      min-width: 992px;
      img {
        width: 100%;
        height: 536px;
      }
      .right {
        position: absolute;
        top: 0;
        right: -290px;
        img {
          width: 700px;
          height: auto;
        }
      }
    }
    .banner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 536px;
      z-index: 2;
    }
    .banner-box {
      width: 1180px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .left {
        width: 640px;
        display: flex;
        flex-direction: column;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          line-height: 51px;
          color: #1d2c67;
        }
        .content {
          width: 640px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.8);
          margin-top: 30px;
          font-size: 18px;
        }
        .product-price {
          width: 80px;
          height: 60px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 60px;
          align-items: center;
          color: #1a61ea;
          cursor: pointer;
          margin: 40px 0 0 40px;
        }
      }
    }
  }
  &-introduce {
    display: flex;
    justify-content: center;
    padding: 120px 0 95px;
    &-box {
      width: 1180px;
      justify-content: space-between;
      display: flex;
      .left {
        img {
          width: 410px;
          height: auto;
        }
      }
      .right {
        font-family: "Helvetica Neue";
        font-style: normal;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .title {
          font-weight: 700;
          font-size: 20px;
          line-height: 100%;
          color: #1d2c67;
          margin-bottom: 24px;
        }
        .desc {
          width: 670px;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.6);
        }
        .open-btn {
          font-family: "Helvetica Neue";
          font-style: normal;
          width: 132px;
          height: 44px;
          background: #3d5dcc;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          color: #ffffff;
          text-align: center;
          margin-top: 40px;
          cursor: pointer;
        }
        .open-btn:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            #1a61ea;
        }
        .border-btn {
          font-family: "Helvetica Neue";
          font-style: normal;
          width: 132px;
          height: 44px;
          border: 1px solid #3d5dcc;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          color: #3d5dcc;
          text-align: center;
          margin-top: 40px;
          cursor: pointer;
          box-sizing: border-box;
          margin-left: 24px;
        }
        .disable-btn {
          width: 132px;
          height: 44px;
          background: #868eaa;
          border-radius: 2px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          color: #e8eaf4;
          margin-top: 40px;
        }
      }
    }
  }
  &-products {
    width: 100%;
    background: url("../../../assets/core/sealabc/core-adv-bg.png") no-repeat
      100% 100%;
    background-position: left top;
    background-size: 100% 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 210px 130px;
    box-sizing: border-box;
    &-title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: #1d2c67;
      width: 100%;
      text-align: center;
    }
    .icon-box {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      .icon {
        width: 274px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          // width: 130px;
          // height: 130px;
          margin: 0 auto;
          display: block;
        }
        .seal-img {
          width: 130px;
          height: 130px;
        }
        .xaas-img {
          width: 120px;
          height: 144px;
        }
        .desc {
          font-family: "Helvetica Neue";
          font-style: normal;
          // font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #15255b;
          margin-top: 24px;
        }
      }
    }
  }
  &-system {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 0 102px;
    &-title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: #1d2c67;
      width: 100%;
      text-align: center;
      margin-bottom: 80px;
    }
    &-box {
      width: 1180px;
      img {
        width: 1180px;
        height: auto;
      }
    }
  }
  &-technology {
    width: 100%;
    height: 860px;
    background: url("../../../assets/solution/skew-bg.png") no-repeat 100% 100%;
    background-position: left top;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 180px;
    box-sizing: border-box;
    &-title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      color: #1d2c67;
      width: 100%;
      text-align: center;
    }
    .icon-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 80px;
      width: 1180px;
      .icon {
        width: 575px;
        height: 180px;
        display: flex;
        padding: 0 45px 0 80px;
        background-color: #fff;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 30px;
        img {
          width: 50px;
          height: 50px;
          margin-right: 40px;
        }
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #15255b;
        }
        .desc {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: rgba(21, 37, 91, 0.8);
          margin-top: 14px;
        }
      }
    }
  }
  &-info {
    width: 100%;
    height: 400px;
    background: url("../../../assets/core/sealabc/core-bg.png") no-repeat 100%
      100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1180px;
      .icon {
        width: 575px;
        height: 200px;
        display: flex;
        padding: 42px 40px 0;
        background: url("../../../assets/core/sealabc/core-little-bg.png")
          no-repeat 100% 100%;
        box-sizing: border-box;
        position: relative;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #15255b;
        }
        .desc {
          width: 330px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: rgba(21, 37, 91, 0.8);
          margin-top: 24px;
        }
        .btn {
          position: absolute;
          top: 80px;
          right: 40px;
          width: 132px;
          height: 44px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          color: #e8eaf4;

          border-radius: 2px;
        }
        .disable {
          background: #868eaa;
        }
        .contact {
          background: #3d5dcc;
          cursor: pointer;
        }
        .contact:hover {
          background-color: #344fad;
        }
      }
    }
  }
}
</style>