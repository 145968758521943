<template>
  <div class="solve">
    <div class="solve-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img src="@/assets/about/about-banner.png" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">区块链+ 领域的开拓者</div>
            <div class="content">创建未来社会信任体系和生产关系的基础设施</div>
          </div>
        </div>
      </div>
    </div>
    <div class="solve-content-tab" id="about-tab">
      <ul class="about-tab">
        <li
          :class="selectedId == 1 ? ' hightlight' : ''"
          @click="changeHash('#about-us', 1)"
        >
          <a>关于我们</a>
        </li>
        <li
          :class="selectedId == 2 ? ' hightlight' : ''"
          @click="changeHash('#company-business', 2)"
        >
          <a>公司业务</a>
        </li>
        <li
          :class="selectedId == 3 ? ' hightlight' : ''"
          @click="changeHash('#vision-mission', 3)"
        >
          <a>愿景和使命</a>
        </li>
        <li
          :class="selectedId == 4 ? ' hightlight' : ''"
          @click="changeHash('#cooperation-consulting', 4)"
        >
          <a>合作咨询</a>
        </li>
      </ul>
    </div>
    <div
      class="solve-content-about wow slideInUp animate__animated_title"
      id="about-us"
    >
      <div class="title">关于我们</div>
      <img src="@/assets/about/Group 806.png" alt="" />
      <div class="desc">
        聚梦创新是来自国内一流技术的区块链企业项目的领导者、核心开发工程师，<br />
        在区块链领域拥有丰富的技术积累和深入的理解。<br />
        我们致力于区块链+ 领域的开拓与创新，<br />
        在政务、金融、农业、版权、医疗健康、物联网、文娱IP等多个领域提供场景化、可落地的全流程区块链系统，<br />
        为产业互联网提供可信、高效的技术解决方案。<br />
        携手行业各界伙伴一同构建产业可信互联、价值互联。<br />
      </div>
    </div>
    <div
      class="solve-content-company wow slideInUp animate__animated_title"
      id="company-business"
    >
      <div class="title">公司业务</div>
      <img src="@/assets/about/Group 806.png" alt="" />
      <div class="desc">
        秉承专业、专注、持续的技术研发与开拓，<br />
        融合微服务架构、密码学算法、分布式账本、多种类共识、混合预言机、异构跨链等多种区块链领域先进技术的自研和创新，<br />
        通过丰富的产品、服务和场景化的解决方案，为不同领域的社会实体提供快速便捷，<br />
        安全可靠的区块链技术方案的设计、接入和落地。<br />
      </div>
    </div>
    <div
      class="solve-content-vision wow slideInUp animate__animated_title"
      id="vision-mission"
    >
      <div class="title">愿景和使命</div>
      <img
        src="@/assets/about/Group 806.png"
        alt=""
        style="width: 46px; height: 46px; margin: 30px 0 60px"
      />
      <div class="vision-box">
        <div class="little-box">
          <img src="@/assets/about/vision.png" alt="" />
          <div class="little-title">愿景</div>
          <div class="desc">立足科技创新<br />赋能实体社会</div>
        </div>
        <div class="little-box">
          <img src="@/assets/about/mission.png" alt="" />
          <div class="little-title">使命</div>
          <div class="desc">让区块链技术成为 <br />未来社会必要的基础组件</div>
        </div>
        <div class="little-box">
          <img src="@/assets/about/value.png" alt="" />
          <div class="little-title">价值观</div>
          <div class="desc">客户第一，开放共赢 <br />专注创新，求真务实</div>
        </div>
      </div>
    </div>
    <div
      class="solve-service wow slideInUp animate__animated_bg"
      id="cooperation-consulting"
    >
      <div class="title">合作咨询</div>
      <div class="desc">
        如果您有意向购买我们的产品、技术服务或希望进行业务相关咨询
      </div>
      <div class="desc">
        如果您有线上或线下的活动合作，采访报道，公关宣传等传媒方向合作意向
      </div>
      <div class="desc">如果您希望与我们一起探索区块链+ 领域的商业机会</div>
      <div class="desc">如果您希望与我们共同促进区块链领域的技术发展</div>
      <div style="display: flex; margin-top: 36px">
        <div class="service-btn" @click="goUrl('/contact')">合作咨询</div>
        <!-- <div class="contact-btn" @click="customerShow = true">快速解疑</div> -->
        <!-- <div class="contact-btn">前往论坛</div> -->
      </div>
    </div>
    <Customer v-show="customerShow" @closeDialog="closeDialog"></Customer>
  </div>
</template>

<script>
import Customer from "../../../components/commonPopup/Customer.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Customer,
  },
  data() {
    return {
      selectedId: 1,
      customerShow: false,
    };
  },
  methods: {
    closeDialog(value) {
      this.customerShow = value;
    },
    goUrl(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
    changeHash(idName, id) {
      var bignav = document.getElementById("about-tab");
      bignav.style.position = "fixed";
      bignav.style.top = "0";
      bignav.style.zIndex = "9999";
      bignav.style.background = "#fff";
      document.querySelector(idName).scrollIntoView({ behavior: "smooth" });
      // this.selectedId = id;
    },
    handleScroll() {
      var us = document.getElementById("about-us");
      var usScroll = us.getBoundingClientRect().top;

      let usScrollHeight = document.getElementById("about-us").scrollHeight;

      var company = document.getElementById("company-business");
      var companyScrollHeight = company.scrollHeight;

      var vision = document.getElementById("vision-mission");
      var visionScrollHeight = vision.scrollHeight;
      var cooperation = document.getElementById("cooperation-consulting");
      var cooperationScrollHeight = cooperation.scrollHeight;

      var bignav = document.getElementById("about-tab");
      var topScroll = bignav.getBoundingClientRect().top;

      if (topScroll < 80) {
        bignav.style.position = "fixed";
        bignav.style.top = "0";
        bignav.style.zIndex = "9999";
        bignav.style.background = "#fff";
        if (usScroll > 80) {
          bignav.style.position = "static";
        }
      } else {
        bignav.style.position = "static";
      }

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;

      if (scrollTop < 696) {
        this.selectedId = 1;
      } else if (scrollTop > 696 && scrollTop < 696 + Number(usScrollHeight)) {
        this.selectedId = 2;
      } else if (
        scrollTop > 696 + Number(usScrollHeight) &&
        scrollTop <
          Number(696) + Number(usScrollHeight) + Number(companyScrollHeight)
      ) {
        this.selectedId = 3;
      } else if (
        scrollTop >
          Number(696) + Number(usScrollHeight) + Number(companyScrollHeight) &&
        scrollTop <
          Number(696) +
            Number(usScrollHeight) +
            Number(companyScrollHeight) +
            Number(visionScrollHeight)
      ) {
        this.selectedId = 4;
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.solve {
  .top {
    margin-top: 80px;
  }
  &-title {
    width: 100%;
    height: 536px;
    background: rgba(245, 247, 252, 0.4);
    position: relative;
    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 25%);
      z-index: 0;
      height: 536px;
      min-width: 992px;
      img {
        width: 100%;
        height: 536px;
      }
      .right {
        position: absolute;
        top: 0;
        right: -286px;
        img {
          width: 700px;
          height: auto;
        }
      }
    }
    .banner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 536px;
      z-index: 2;
    }
    .banner-box {
      width: 1180px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .left {
        width: 640px;
        display: flex;
        flex-direction: column;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          line-height: 51px;
          color: #1d2c67;
        }
        .content {
          width: 640px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.8);
          margin-top: 30px;
        }
        .open-btn {
          font-family: "Helvetica Neue";
          font-style: normal;
          width: 112px;
          height: 44px;
          background: #3d5dcc;
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          color: #ffffff;
          text-align: center;
          margin-top: 40px;
          cursor: pointer;
        }
        .open-btn:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            #1a61ea;
        }
        .product-price {
          width: 80px;
          height: 60px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 60px;
          align-items: center;
          color: #1a61ea;
          cursor: pointer;
          margin: 40px 0 0 40px;
        }
      }
      .right {
        img {
          width: 700px;
          height: auto;
        }
      }
    }
  }
  &-content-tab {
    display: flex;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(67, 127, 236, 0.06);
    height: 80px;
    width: 100%;
    .about-tab {
      display: flex;
      list-style: none;
      width: 1180px;
      height: 80px;
      align-items: center;
      li {
        width: 295px;
        text-align: center;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        height: 80px;
        line-height: 80px;
        color: #1d2c67;
        cursor: pointer;
      }
    }
  }
  &-content-about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 127px;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      display: flex;
      align-items: center;
      color: #1d2c67;
    }
    img {
      width: 46px;
      height: 46px;
      margin: 30px 0 60px;
    }
    .desc {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(29, 44, 103, 0.6);
    }
  }
  &-content-company {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 140px 0 0;
    box-sizing: border-box;
    background: url("../../../assets/solution/skew-bg.png") no-repeat 100% 100%;
    background-position: left top;
    background-size: 100% 100%;
    height: 650px;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      display: flex;
      align-items: center;
      color: #1d2c67;
    }
    img {
      width: 46px;
      height: 46px;
      margin: 30px 0 60px;
    }
    .desc {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(29, 44, 103, 0.6);
    }
  }
  &-content-vision {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 75px;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      display: flex;
      align-items: center;
      color: #1d2c67;
    }

    .vision-box {
      width: 1180px;
      display: flex;
      justify-content: space-between;
      .little-box {
        width: 374px;
        height: 210px;
        background: #f5f7fc;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .little-title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #1d2c67;
        margin-top: -15px;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        display: flex;
        align-items: center;
        color: #1d2c67;
        margin-top: 30px;
        text-align: center;
      }
    }
  }
  &-service {
    width: 100%;
    background: url("../../../assets/about/about-bg.png") no-repeat 100% 100%;
    padding: 180px 0 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      /* identical to box height */
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 60px;
    }
    .desc {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .service-btn {
      width: 132px;
      height: 48px;
      background: #ffffff;
      border-radius: 2px;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #3d5dcc;
      line-height: 48px;
      cursor: pointer;
    }
    .service-btn:hover {
      background-color: #e6e6e6;
    }
    .contact-btn {
      box-sizing: border-box;
      width: 132px;
      height: 48px;
      border: 1px solid #ffffff;
      border-radius: 2px;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 48px;
      text-align: center;
      color: #ffffff;
      margin-left: 24px;
      cursor: pointer;
    }
    .contact-btn:hover {
      background-color: #fff;
      color: #3d5dcc;
    }
  }
  .hightlight {
    border-bottom: 3px solid #3d5dcc;
    color: #3d5dcc !important;
  }
}
</style>