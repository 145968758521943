<template>
  <div class="contact">
    <ContactMobile v-if="mobile"></ContactMobile>
    <ContactPC v-if="!mobile" style="min-width:1280px"></ContactPC>
  </div>
</template>

<script>
import ContactMobile from "./modules/ContactMobile.vue";
import ContactPC from "./modules/ContactPC.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    ContactMobile,
    ContactPC,
  },
  data() {
    return {
      mobile: false,
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>