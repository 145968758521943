<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePcOther :programmeArr="programmeArr"></ProgrammePcOther>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <ProgrammeOther :programmeArr="programmeArr"></ProgrammeOther>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import ProgrammeOther from "./modules/ProgrammeOther.vue";
import ProgrammePcOther from "./modules/ProgrammePcOther.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    ProgrammeOther,
    ProgrammePcOther,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链 + 版权",
        desc: " 为创作者、版权所有者、版权消费者、版权认证机构、交易平台等各方提供统一的管理交易维护平台，提升生态中的各种业务效率，促进版权生态整合与发展。",
        imgUrl: require("@/assets/solution/copyright/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链 + 版权",
        desc: " 为创作者、版权所有者、版权消费者、版权认证机构、交易平台等各方提供统一的管理交易维护平台，提升生态中的各种业务效率，促进版权生态整合与发展。",
        imgUrl: require("@/assets/solution/copyright/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "版权管理不规范",
          desc: "缺乏行业规范化服务，版权交易过程中信息不联通，版权收益难以公平有效地在原创作者和相关机构间分配。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "内容保护成本高",
          desc: "传统保护费时费力成本高，海量内容难以全量保护，内容分发难以掌控传播安全问题。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "版权登记繁琐",
          desc: "传统模式权属自证繁琐，需奔波于多个部门/组织之间，侵权发现难，维权耗时久成本高，多数作品难以被有效保护。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/copyright/copyright-icon-1.png"),
            desc: "以版权所有者身份为核心，将版权确权、交易、分发等业务进行统一管理，让版权管理清晰明了，安全可靠。",
            title: "身份真实可验、版权流转透明",
          },
          {
            imgUrl: require("@/assets/solution/copyright/copyright-icon-2.png"),
            desc: "丰富的交易方式，安全可靠的交易平台，公平可信的监管平台，让版权价值最大化。",
            title: "交易真实可溯、分发授权安全",
          },
          {
            imgUrl: require("@/assets/solution/copyright/copyright-icon-3.png"),
            desc: "基于内容比对、指纹识别、可信流转记录、可信数据存证等技术，为版权保护提供强力的司法证据支持。",
            title: "数据存储安全、取证方便快捷",
          },
          {
            imgUrl: require("@/assets/solution/copyright/copyright-icon-4.png"),
            desc: "将分散的业务环节整合成线上一站式服务，集权益存证、传播监测、电子取证、维权服务为一体,让原创作者/机构“一次都不跑”就实现版权保护。",
            title: "整合业务并线、零步版权登记",
          },
        ],
        title: "提升确权效率，丰富交易方式，降低维权难度。",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/copyright/copyright-management.png"),
          name: "版权管理",
          grade: "first",
        },
        {
          imgUrl: require("@/assets/solution/copyright/copyright-trading.png"),
          name: "版权交易",
          grade: "second",
        },
        {
          imgUrl: require("@/assets/solution/copyright/copyright-protection.png"),
          name: "版权保护",
          grade: "third",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>