<template>
  <div class="solve-programme" style="padding-bottom: 160px">
    <div class="solve-programme-title wow slideInUp animate__animated_title">
      方案价值
    </div>
    <div class="subheading-title wow slideInUp animate__animated_title">
      {{ programmeArr.title }}
    </div>
    <div class="icon-box-other wow slideInUp animate__animated_title">
      <div
        class="icon"
        v-for="(item, index) in programmeArr.iconArr"
        :key="index"
      >
        <div>
          <img :src="item.imgUrl" />
        </div>
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  props: {
    programmeArr: Object,
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.solve-programme {
  width: 100%;
  background: url("../../../assets/solution/skew-bg.png") no-repeat 100% 100%;
  background-position: left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 180px 0 260px;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
  }
  .subheading-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(29, 44, 103, 0.8);
    margin: 27px 0 30px;
  }
  .icon-box {
    display: flex;
    justify-content: space-between;
    .icon {
      width: 280px;
      text-align: center;
      padding: 36px 0;
      img {
        width: 64px;
        height: 64px;
        margin: 0 auto;
        display: block;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        // font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #15255b;
        margin-top: 24px;
      }
    }
  }
  .icon-box-other {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1180px;
    .icon {
      width: 575px;
      height: 180px;
      display: flex;
      padding: 34px 50px 0 60px;
      box-sizing: border-box;
      align-items: center;
      margin-top: 30px;
      img {
        width: 64px;
        height: 64px;
        margin-right: 40px;
      }
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #1d2c67;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: rgba(29, 44, 103, 0.6);
        margin-top: 20px;
      }
    }
  }
}
</style>