<template>
  <div class="products-list">
    <div
      class="menu-box"
      v-for="(item, index) in productsArr"
      :key="index"
      @click="goUrl(item.path)"
    >
      <div class="menu-box-left">
        <img :src="item.img" alt="" />
      </div>
      <div class="menu-box-right">
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productsArr: [
        {
          img: require("@/assets/nav/xaas.png"),
          title: "XaaS云服务",
          desc: "围绕Web 3.0业务的一站式服务平台",
          path: "/xaas",
        },
        {
          img: require("@/assets/nav/sealabc.png"),
          title: "SealABC",
          desc: "高速共识算法，硬件密码学组件，组件化积木式搭建方式",
          path: "/sealabc",
        },
      ],
    };
  },
  methods: {
    goUrl(path) {
      this.close();
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
    close() {
      this.$emit("closeProducts", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-list {
  background-color: #fff;
  top: 80px;
  left: -188px;
  position: absolute;
  .menu-box {
    width: 440px;
    height: 80px;
    padding: 20px 25px 20px 30px;
    box-sizing: border-box;
    display: flex;
    &-left {
      margin-right: 20px;
      img {
        width: 40px;
        height: 40px;
        vertical-align: top;
      }
    }
    &-right {
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        color: #1d2c67;
        margin-right: 20px;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: rgba(29, 44, 103, 0.4);
        margin-top: 12px;
      }
    }
  }
  .menu-box:hover {
    background: linear-gradient(102.53deg, #f1f5ff 9.09%, #ffffff 89.94%);
    .title {
      color: #3d5dcc;
    }
  }
}
</style>