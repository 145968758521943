<template>
  <div class="solve">
    <div class="banner">
      <div class="header-image">
        <img src="@/assets/mobile/banner-bg.png" alt="" />
        <div class="banner-image">
          <img src="@/assets/about/about-banner.png" alt="" />
        </div>
      </div>
      <div class="banner-box">
        <div class="banner-title">区块链+ 领域的开拓者</div>
        <div class="banner-desc">创建未来社会信任体系和生产关系的基础设施</div>
      </div>
    </div>
    <div class="solve-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img src="@/assets/about/about-banner.png" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">区块链+ 领域的开拓者</div>
            <div class="content">创建未来社会信任体系和生产关系的基础设施</div>
          </div>
        </div>
      </div>
    </div>
    <div class="solve-content-about wow slideInUp animate__animated_title">
      <div class="title">关于我们</div>
      <img src="@/assets/about/Group 806.png" alt="" />
      <div class="desc">
        聚梦创新是来自国内一流技术的区块链企业项目的领导者、核心开发工程师，
        在区块链领域拥有丰富的技术积累和深入的理解。
        <br />
        <br />
      </div>
      <div class="desc">
        我们致力于区块链+ 领域的开拓与创新，
        在政务、金融、农业、版权、医疗健康、物联网、文娱IP等多个领域提供场景化、可落地的全流程区块链系统，
        为产业互联网提供可信、高效的技术解决方案。
        携手行业各界伙伴一同构建产业可信互联、价值互联。
      </div>
    </div>
    <div class="solve-content-company wow slideInUp animate__animated_title">
      <div class="title">公司业务</div>
      <img src="@/assets/about/Group 806.png" alt="" />
      <div class="desc">
        秉承专业、专注、持续的技术研发与开拓，
        融合微服务架构、密码学算法、分布式账本、多种类共识、混合预言机、异构跨链等多种区块链领域先进技术的自研和创新，
        通过丰富的产品、服务和场景化的解决方案，为不同领域的社会实体提供快速便捷，
        安全可靠的区块链技术方案的设计、接入和落地。<br />
      </div>
    </div>
    <div class="solve-content-vision wow slideInUp animate__animated_title">
      <div class="title">愿景和使命</div>
      <img
        src="@/assets/about/Group 806.png"
        alt=""
        style="width: 30px; height: 30px; margin: 15px 0 20px"
      />
      <div class="vision-box">
        <div class="little-box">
          <img src="@/assets/about/vision.png" alt="" />
          <div class="little-title">愿景</div>
          <div class="desc">立足科技创新<br />赋能实体社会</div>
        </div>
        <div class="little-box">
          <img src="@/assets/about/mission.png" alt="" />
          <div class="little-title">使命</div>
          <div class="desc">让区块链技术成为未来<br />社会必要的基础组件</div>
        </div>
        <div class="little-box">
          <img src="@/assets/about/value.png" alt="" />
          <div class="little-title">价值观</div>
          <div class="desc">
            客户第一 <br />开放共赢 <br />专注创新 <br />求真务实
          </div>
        </div>
      </div>
    </div>
    <div class="solve-service wow slideInUp animate__animated_bg">
      <div class="title">合作咨询</div>
      <div class="desc">
        如果您有意向购买我们的产品、技术服务或希望进行业务相关咨询
      </div>
      <div class="desc">
        如果您有线上或线下的活动合作，采访报道，公关宣传等传媒方向合作意向
      </div>
      <div class="desc">如果您希望与我们一起探索区块链+ 领域的商业机会</div>
      <div class="desc">如果您希望与我们共同促进区块链领域的技术发展</div>
      <div class="service-btn" @click="goUrl('/contact')">合作咨询</div>
      <!-- <div class="contact-btn" @click="customerShow = true">快速解疑</div> -->
      <!-- <div class="service-btn">前往论坛</div> -->
    </div>
    <div class="solve-service-ipad wow slideInUp animate__animated_bg">
      <div class="title">合作咨询</div>
      <div class="desc">
        如果您有意向购买我们的产品、技术服务或希望进行业务相关咨询
      </div>
      <div class="desc">
        如果您有线上或线下的活动合作，采访报道，公关宣传等传媒方向合作意向
      </div>
      <div class="desc">如果您希望与我们一起探索区块链+ 领域的商业机会</div>
      <div class="desc">如果您希望与我们共同促进区块链领域的技术发展</div>
      <div style="display: flex; margin-top: 20px">
        <div class="service-btn" @click="goUrl('/contact')">合作咨询</div>
        <!-- <div class="contact-btn" @click="customerShow = true">快速解疑</div> -->
        <div class="contact-btn">前往论坛</div>
      </div>
    </div>
    <Customer v-show="customerShow" @closeDialog="closeDialog"></Customer>
  </div>
</template>

<script>
import Customer from "../../../components/commonPopup/Customer.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Customer,
  },
  data() {
    return {
      customerShow: false,
    };
  },
  methods: {
    closeDialog(value) {
      this.customerShow = value;
    },
    goUrl(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.solve {
  .top {
    margin-top: 80px;
  }
  &-content-about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 15px 22px;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      align-items: center;
      color: #1d2c67;
    }
    img {
      width: 30px;
      height: 30px;
      margin: 15px 0 20px;
    }
    .desc {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #1d2c67;
      opacity: 0.6;
    }
  }
  &-content-company {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 15px 46px;
    box-sizing: border-box;
    background: url("../../../assets/mobile/skew-bg.png") no-repeat 100% 100%;
    background-position: left top;
    background-size: 100% 100%;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #1d2c67;
    }
    img {
      width: 30px;
      height: 30px;
      margin: 15px 0 20px;
    }
    .desc {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(29, 44, 103, 0.6);
    }
  }
  &-content-vision {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 50px;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #1d2c67;
    }
    .vision-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      box-sizing: border-box;
      .little-box {
        flex: 1;
        height: 150px;
        background: #f5f7fc;
        border-radius: 4px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 10px;
        box-sizing: border-box;
        img {
          height: 16px;
          width: auto;
          margin-top: 20px;
        }
      }
      .little-box:nth-child(1) {
        margin-right: 15px;
      }
      .little-box:nth-child(2) {
        margin-right: 15px;
      }
      .little-title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #1d2c67;
        margin-top: -7px;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        color: rgba(29, 44, 103, 0.6);
        margin-top: 15px;
        text-align: center;
      }
    }
  }
  &-service {
    display: block;
    width: 100%;
    background: url("../../../assets/mobile/verision-bg.png") no-repeat 100%
      100%;
    padding: 50px 15px 42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #ffffff;
      margin-bottom: 30px;
    }
    .desc {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 24px;
      width: 100%;
      text-align: left;
    }
    .des:last-child {
      margin-bottom: 15px;
    }
    .service-btn {
      width: 100%;
      height: 45px;
      background: #ffffff;
      border-radius: 2px;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #1d2c67;
      line-height: 45px;
      cursor: pointer;
      margin-top: 15px;
    }
  }
  &-service-ipad {
    display: none;
  }
  .hightlight {
    border-bottom: 3px solid #3d5dcc;
    color: #3d5dcc !important;
  }
}
.banner {
  display: block;
  width: 100%;
  height: 480px;
  background: #f5f7fc;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 480px;
    img {
      width: 100%;
      height: 345px;
    }
    .banner-image {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .banner-box {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 30px;
    box-sizing: border-box;
    font-family: "Helvetica Neue";
    font-style: normal;
    width: 100%;
    .banner-title {
      font-weight: 700;
      font-size: 23px;
      line-height: 100%;
      text-align: center;
      width: 100%;
      color: #1d2c67;
      margin-top: 60px;
    }
    .banner-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      width: 100%;
      text-align: center;
      color: rgba(29, 44, 103, 0.8);
      margin-top: 20px;
    }
  }
}
.solve-title {
  display: none;
}
@media screen and (min-width: 575px) and (max-width: 1180px) {
  .solve {
    &-service {
      display: none;
    }
    &-service-ipad {
      display: block;
      width: 100%;
      background: url("../../../assets/about/about-bg.png") no-repeat 100% 100%;
      background-position: top left;
      padding: 105px 0 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -40px;
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height */
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 22px;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 24px;
      }
      .service-btn {
        width: 150px;
        height: 45px;
        background: #ffffff;
        border-radius: 2px;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #3d5dcc;
        line-height: 45px;
        cursor: pointer;
      }
      .service-btn:hover {
        background-color: #e6e6e6;
      }
      .contact-btn {
        box-sizing: border-box;
        width: 150px;
        height: 45px;
        border: 1px solid #ffffff;
        border-radius: 2px;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 45px;
        text-align: center;
        color: #ffffff;
        margin-left: 15px;
        cursor: pointer;
      }
      .contact-btn:hover {
        background-color: #fff;
        color: #3d5dcc;
      }
    }
    .banner {
      display: none;
    }
    &-title {
      display: block;
      width: 100%;
      height: 268px;
      background: rgba(245, 247, 252, 0.4);
      position: relative;
      .header-image {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100vw - 25%);
        z-index: 0;
        height: 268px;
        img {
          width: 100%;
          height: 268px;
        }
        .right {
          position: absolute;
          top: 0;
          right: -25%;
          width: 50%;
          display: flex;
          align-items: center;
          height: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .banner {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        width: calc(100vw - 25%);
        height: 268px;
        z-index: 2;
        background: transparent;
      }
      .banner-box {
        width: calc(100vw - 25%);
        height: 268px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          flex-direction: column;
          .title {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 700;
            font-size: 21px;
            line-height: 25px;
            color: #1d2c67;
          }
          .content {
            width:100%;
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 160%;
            color: rgba(29, 44, 103, 0.8);
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>