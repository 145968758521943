 <template>
  <div class="footer-box">
    <div class="footer-menu">
      <footer class="footer">
        <div class="footer-top">
          <ul>
            <li>关于公司</li>
            <li @click="toTechnology('/about')">公司简介</li>
            <li @click="toTechnology('/contact')">合作咨询</li>
            <li @click="toHome(3)">问题留言</li>
          </ul>
          <ul>
            <li>核心产品</li>
            <li @click="toTechnology('/xaas')">XaaS云服务</li>
            <li @click="toTechnology('/sealabc')">SealABC</li>
          </ul>
        </div>
        <div class="footer-bottom">
          <div class="title">解决方案</div>
          <div class="aa">
            <div @click="toTechnology('/government')">政务</div>
            <div @click="toTechnology('/healthy')">医疗健康</div>
            <div @click="toTechnology('/finance')">金融</div>
            <div @click="toTechnology('/ticketing')">票务服务</div>
            <div @click="toTechnology('/agriculture')">农业</div>
            <div @click="toTechnology('/did')">DID身份</div>
            <div @click="toTechnology('/copyright')">版权</div>
            <div @click="toTechnology('/nft')">数字藏品</div>
            <div @click="toTechnology('/internet')">物联网</div>
            <div @click="toTechnology('/ens')">域名服务</div>
            <div @click="toTechnology('/entertainment')">文娱IP</div>
          </div>
        </div>
      </footer>
    </div>
    <div class="copy-right">
      <div class="nav-img">
        <img src="@/assets/footer/footer-logo.png" alt="" />
      </div>
      <div>京ICP备19056943号</div>
      <div>Copyright © 2020 dreamchains.cn All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async toTechnology(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-box {
  width: 100%;
  height: 607px;
  background-color: #272e40;
  color: rgba(255, 255, 255, 0.6);
}
.footer-menu {
  width: 100%;
  height: 448px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #272e40;
  color: rgba(255, 255, 255, 0.6);
  padding: 40px 0;
  box-sizing: border-box;
}
.footer {
  .footer-top {
    display: flex;
    ul:first-child {
      margin-right: 70px;
    }
  }
  .footer-bottom {
    width: 200px;
    margin-top: 10px;
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
    }
    .aa {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      div:nth-child(2n + 1) {
        width: 64px;
        margin-right: 78px;
      }
      div {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        cursor: pointer;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 20px;
      }
      div:hover {
        color: #ffffff;
      }
    }
  }

  ul {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
  li {
    list-style: none;
    margin-bottom: 20px;
    text-align: center;
    height: 14px;
  }
  li:first-child {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
  }
}
.copy-right {
  width: 100%;
  height: 159px;
  padding: 30px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #272e40;
  color: rgba(255, 255, 255, 0.4);
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  .nav-img {
    img {
      width: auto;
      height: 35px;
      margin-bottom: 20px;
    }
  }
  div {
    margin-top: 10px;
  }
}
// @media screen and (min-width: 500px) and (max-width: 750px) {
//   .footer-menu {
//     height: 240px;
//   }
//   .footer {
//     display: inline-block;
//     padding: 0;
//     &-left {
//       .nav-img {
//         margin-top: 30px;
//       }
//       justify-content: initial;
//       padding-left: 32px;
//       .seor-info {
//         margin-top: 26px;
//       }
//     }
//     &-right {
//       display: block;
//       padding-left: 32px;
//       margin-top: 48px;
//       padding-bottom: 32px;
//       ul {
//         margin-top: 32px;
//         height: auto;
//       }
//     }
//   }
// }
// @media screen and (max-width: 500px) {
//   .footer-menu {
//     height: 240px;
//   }
//   .footer {
//     display: inline-block;
//     padding-left: 32px;
//     &-left {
//       .nav-img {
//         margin-top: 30px;
//       }
//       justify-content: initial;
//       padding-left: 32px;
//       .seor-info {
//         margin-top: 26px;
//       }
//     }
//     &-right {
//       display: block;
//       padding-left: 32px;
//       margin-top: 48px;
//       padding-bottom: 32px;
//       ul {
//         margin-top: 32px;
//         height: auto;
//       }
//     }
//   }
// }

// @media screen and (min-width: 750px) and (max-width: 1220px) {
//   .footer {
//     padding: 0 50px;
//     &-left {
//       flex-basis: 32%;
//     }
//     &-right {
//       flex-basis: 60%;
//     }
//   }
// }
</style>