<template>
  <div>
    <Core v-if="mobile" :coreArr="coreArr"></Core>
    <CorePc v-if="!mobile" style="min-width:1280px" :coreArr="coreArr"></CorePc>
  </div>
</template>

<script>
import Core from "./modules/Core.vue";
import CorePc from "./modules/CorePc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: { Core, CorePc },
  data() {
    return {
      mobile: false,
      coreArr: {
        bannerImage: require("@/assets/core/sealabc/xaas-banner.png"),
        bannerTitle: "XaaS云服务",
        bannerDesc:
          "围绕Web 3.0业务的一站式服务平台，为用户快速启动Web 3.0业务提供强大的技术支撑",
        introduceTitle: "Web 3.0一站式服务",
        introduceImage: require("@/assets/core/xaas/introduce-info.png"),
        introduceDesc:
          "XaaS平台为您提供了已经部署并运行于多条区块链上的、安全可靠的智能合约，以及管理专属的NFT业务智能合约集合，支持NFT的名称、符号定制，独立NFT仓库管理。通过针对不同场景的智能合约集合的独立部署，帮助您快速创建NFT业务矩阵、品牌矩阵，打造全新的Web 3.0世界。",
        trialStatus: 1,
        technologyArr: [
          {
            imgUrl: require("@/assets/core/xaas/platform-icon1.png"),
            title: "高效的区块链接入",
            desc: "简洁高效的区块链接入，快速链接 Web 3.0的世界",
          },
          {
            imgUrl: require("@/assets/core/xaas/platform-icon2.png"),
            title: "场景应用支持",
            desc: "丰富场景应用支持，帮助用户突破业务边界",
          },
          {
            imgUrl: require("@/assets/core/xaas/platform-icon3.png"),
            title: "多链标准化服务",
            desc: "多链标准化服务，一次编码多链通行",
          },
          {
            imgUrl: require("@/assets/core/xaas/platform-icon4.png"),
            title: "多链可靠交互",
            desc: "可视化合约部署、链上交互、运维监控，让区块链系统使用更简洁。",
          },
        ],
        systemImage: require("@/assets/core/xaas/products-XaaS.png"),
        productsArr: [
          {
            imgUrl: require("@/assets/core/xaas/advantage-icon1.png"),
            desc: "自主可控XaaS平台",
          },
          {
            imgUrl: require("@/assets/core/xaas/advantage-icon2.png"),
            desc: "支持多类型场景",
          },
          {
            imgUrl: require("@/assets/core/xaas/advantage-icon3.png"),
            desc: "灵活支持多种底层框架",
          },
          {
            imgUrl: require("@/assets/core/xaas/advantage-icon4.png"),
            desc: "安全可信的仓库服务",
          },
        ],
      },
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },

  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
  
  
};
</script>

<style lang="scss" scoped>
</style>