<template>
  <div class="solve-title">
    <div class="header-image">
      <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
      <div class="right">
        <img :src="bannerArr.imgUrl" alt="" />
      </div>
    </div>
    <div class="banner">
      <div class="banner-box">
        <div class="left">
          <div class="title">
            {{ bannerArr.title }}
          </div>
          <div class="content">
            {{ bannerArr.desc }}
          </div>
          <div style="display: flex">
            <Contactus></Contactus>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contactus from "./Contactus.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Contactus,
  },
  props: {
    bannerArr: Object,
    required: true,
  },
  data() {
    return {};
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.solve-title {
  width: 100%;
  height: 536px;
  background: rgba(245, 247, 252, 0.4);
  position: relative;
  .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 25%);
    z-index: 0;
    height: 536px;
    min-width: 992px;
    img {
      width: 100%;
      height: 536px;
    }
    .right {
      position: absolute;
      top: 0;
      right: -286px;
      img {
        width: 700px;
        height: auto;
      }
    }
  }
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 536px;
    z-index: 2;
  }
  .banner-box {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .left {
      width: 640px;
      display: flex;
      flex-direction: column;
      .title {
        width: 640px;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 51px;
        color: #1d2c67;
      }
      .content {
        width: 640px;
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        color: rgba(29, 44, 103, 0.8);
        margin-top: 30px;
      }
      .open-btn {
        font-family: "Helvetica Neue";
        font-style: normal;
        width: 140px;
        height: 44px;
        background: #3d5dcc;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 44px;
        color: #ffffff;
        text-align: center;
        margin-top: 40px;
        cursor: pointer;
      }
      .open-btn:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          #1a61ea;
      }
      .product-price {
        width: 80px;
        height: 60px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 60px;
        align-items: center;
        color: #1a61ea;
        cursor: pointer;
        margin: 40px 0 0 40px;
      }
    }
  }
}
</style>