<template>
  <div class="home">
    <HomeMobile v-if="mobile"></HomeMobile>
    <HomePC v-if="!mobile" style="min-width:1280px"></HomePC>
  </div>
</template>

<script>
import HomeMobile from "./modules/HomeMobile.vue";
import HomePC from "./modules/HomePC.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    HomeMobile,
    HomePC,
  },
  data() {
    return {
      mobile: false,
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>