<template>
  <div class="contact-box">
    <div class="contact">
      <div class="text-desc">
        <div class="title">区块链解决方案咨询</div>
        <div class="desc">
          尊敬的客户您好！<br />
          非常感谢您对聚梦创新区块链解决方案的关注，<br />
          请填写您的联系方式，我们会主动联系到您，谢谢！<br />
        </div>
      </div>
      <el-form
        ref="applyForm"
        :model="applyForm"
        :rules="applyRules"
        label-width="120px"
        class="demo-ruleForm"
        :label-position="labelPosition"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="applyForm.name"
            placeholder="请输入您的姓名"
            size="large"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="telephone">
          <el-input
            v-model="applyForm.telephone"
            placeholder="请输入您的手机号码"
            size="large"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="company">
          <el-input
            v-model="applyForm.company"
            placeholder="请输入您的公司名称"
            size="large"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司行业类别" prop="options">
          <el-select v-model="applyForm.options" placeholder="请选择">
            <el-option
              v-for="item in optionsArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="合作/咨询内容" prop="message">
          <el-input
            v-model="applyForm.message"
            placeholder="请简单介绍一下合作/咨询内容(不超过500字)"
            size="large"
            type="textarea"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- submit -->
        <el-form-item class="register-btn">
          <div class="apply-btn" @click="submitForm()">提交</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>


<script lang="ts">
import { Message, Loading } from "element-ui";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      var reg = /^1[3-9]{1}[0-9]{9}$/;
      if (value === "") {
        callback(new Error("手机号码不能为空"));
      } else if (reg.test(value) == false) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      labelPosition: "top",
      optionsArr: [
        {
          value: "政务",
          label: "政务",
        },
        {
          value: "金融",
          label: "金融",
        },
        {
          value: "农业",
          label: "农业",
        },
        {
          value: "版权",
          label: "版权",
        },
        {
          value: "医疗健康",
          label: "医疗健康",
        },
        {
          value: "物联网",
          label: "物联网",
        },
        {
          value: "文娱IP",
          label: "文娱IP",
        },
        {
          value: "DID身份",
          label: "DID身份",
        },
        {
          value: "NFT数字藏品",
          label: "NFT数字藏品",
        },
        {
          value: "票务系统",
          label: "票务系统",
        },
        {
          value: "域名服务",
          label: "域名服务",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      applyForm: {
        name: "",
        company: "",
        telephone: "",
        message: "",
        options: "",
      },

      applyRules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        company: [
          { required: true, message: "所属公司不能为空", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        options: [
          {
            required: true,
            message: "请至少选择一个公司行业类别",
            // trigger: "change",
          },
        ],
        message: [
          { required: true, message: "合作/咨询内容不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs["applyForm"].validate((valid, object) => {
        if (valid) {
          this.loadingInstance = Loading.service({ fullscreen: true });
          this.$axios
            .post("https://contact.jmcx.io/addContract", {
              params: {
                name: this.applyForm.name,
                phone: this.applyForm.telephone,
                bussiness: this.applyForm.company,
                bussinessType: this.applyForm.options,
                content: this.applyForm.message,
              },
            })
            .then((res) => {
              this.$message({
                message: "提交申请成功，我们会主动联系您，请您耐心等待",
                type: "success",
              });
              this.loadingInstance.close();
              this.$refs["applyForm"].resetFields();
            })
            .catch((err) => {
              this.$message({
                message: "网络不稳定，提交申请失败，请稍后重试",
                type: "error",
              });
              this.loadingInstance.close();
            });
        } else {
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: "center",
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: "smooth",
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-box {
  width: 100%;
  background: url("../../../assets/mobile/form-logo.png") no-repeat 100% 100%;
  font-family: "Inter";
  font-style: normal;
  box-sizing: border-box;
  height: 855px;
  position: relative;
  background-size: cover;
  .contact {
    height: 855px;
    width: 100%;
    padding: 30px 15px 0;
    box-sizing: border-box;
    .text-desc {
      width: 100%;
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 100%;
        text-align: center;
        color: #ffffff;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;

        text-align: center;

        color: rgba(255, 255, 255, 0.8);
        margin-top: 15px;
      }
    }
    .demo-ruleForm {
      background: #ffffff;
      border-radius: 10px;
      margin-top: 20px;
      padding: 15px 20px 10px;
      box-sizing: border-box;
    }
    :deep().el-input--large .el-input__inner {
      height: 45px;
      line-height: 45px;
      background: #f9f9fb;
      border: 0;
    }
    :deep().el-form-item--default .el-form-item__label {
      line-height: 45px;
    }
    :deep().el-textarea__inner {
      height: 150px !important;
    }
    :deep().el-form-item__label {
      line-height: 14px;
      color: #1d2c67;
    }
    :deep().el-form-item {
      margin-bottom: 20px;
    }
    :deep().el-form-item__label:before {
      display: none;
    }
    :deep().el-select {
      width: 100%;
    }
    :deep().el-select .el-input__inner {
      width: 100%;
      height: 46px;
      line-height: 46px;
      background: #f9f9fb;
      border: 0;
    }
    :deep().el-textarea__inner {
      background: #f9f9fb;
      border: 0;
    }
    :deep().el-textarea .el-input__count {
      background: #f9f9fb;
    }
    .apply-btn {
      width: 100%;
      height: 45px;
      background: #3d5dcc;
      border-radius: 2px;
      font-size: 14px;
      line-height: 45px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      margin-top: 10px;
    }
  }
  :deep().el-message .el-icon-success {
    color: #00a870;
  }
  :deep().el-message--success .el-message__content {
    color: #00a870;
  }
  :deep().el-message--success {
    background-color: #f0f9eb;
    border-color: #f0f9eb;
  }
}
</style>