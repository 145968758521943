<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
#app {
  font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
  color: transparent;
}
a {
  text-decoration: none;
}
</style>
