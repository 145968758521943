<template>
  <div class="home">
    <div class="home-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img src="@/assets/home/home-banner.png" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">
              聚集梦想 & 创新未来 <br />致力于区块链+ 领域的开拓与创新
            </div>
            <div class="content">
              聚梦创新为企业及开发者提供高性能、高可用和高安全的区块链技术服务，帮助企业和开发人员快速、低成本的创建、部署、管理和使用区块链技术。
            </div>
            <!-- <div style="display: flex">
            <div class="open-btn">探索解决方案</div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="home-technology wow slideInUp animate__animated_title">
      <div class="home-technology-title">自研创新的区块链+产品</div>
      <div class="icon-box">
        <div
          class="icon"
          v-for="(item, index) in innovateArr"
          :key="index"
          @mouseenter="enter(index)"
          @mouseleave="leave(index)"
          @click="goUrl(item.path)"
          :style="{filter:(index == innovateId ? '' : 'none')}"
        >
          <div
            class="dd animate-other animate__fadeInLeft"
            v-if="index == innovateId"
          ></div>
          <div>
            <img :src="item.imgActive" alt="" v-if="index == innovateId" />
            <img :src="item.img" alt="" v-else />
          </div>
          <div>
            <div class="title">{{ item.title }}</div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-introduce">
      <div class="home-introduce-title">助力各行业价值互联网解决方案</div>
      <div class="home-introduce-box">
        <div class="tab">
          <div
            class="tab-box animate__animated"
            v-for="item in tabArr"
            :key="item.id"
            @mouseenter="enterSolve(item.id)"
          >
            {{ item.name }}
          </div>
          <div
            class="
              absolute
              top-[16px]
              left-[12px]
              h-[52px]
              w-[212px]
              Home_active__sqoHq
            "
            id="cc"
            style="z-index: -1"
          ></div>
        </div>
        <div class="content">
          <div class="overflow-hidden">
            <div id="content">
              <div
                class="swiper-slide"
                :class="tabIndex == index ? 'active' : ''"
                v-for="(item, index) in contentArr"
                style="height: 347px"
                :key="index"
              >
                <div class="ques-title">行业问题</div>
                <div class="ques-desc">{{ item.questionDesc }}</div>
                <el-divider></el-divider>
                <div class="ques-title">解决方案</div>
                <div class="ques-desc">{{ item.answerDesc }}</div>
              </div>
            </div>
          </div>
          <div class="detail-btn" @click="goUrl(contentArr[tabIndex].path)">
            了解方案详情
          </div>
        </div>
        <div class="right-icon">
          <div class="icon-title">行业痛点</div>
          <div
            class="icon-desc"
            v-for="ele in contentArr[tabIndex].iconArr"
            :key="ele.id"
          >
            <img :src="ele.imgUrl" alt="" />
            <div class="text">{{ ele.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-products wow slideInUp animate__animated_title">
      <div class="home-products-title">多样化区块链服务</div>
      <div class="icon-box">
        <div
          class="icon sence animate__animated"
          v-for="(item, index) in diversifyArr"
          :key="index"
          :class="{
            mouse: index != current,
            animate__fadeInRight: index == current,
          }"
        >
          <img v-if="show && index == current" :src="item.imgBgUrl" alt="" />
          <img
            v-else
            :src="item.imgUrl"
            alt=""
            @mouseenter="removeActive($event, index)"
          />
          <div :class="index == current ? 'title-selected' : 'title'">
            {{ item.title }}
          </div>
          <div
            class="desc animate__fadeInUp animate__animated"
            v-if="show && index == current"
          >
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>

    <div class="home-system">
      <div class="home-system-title wow slideInUp animate__animated_title">
        支持行业应用场景
      </div>
      <div class="home-system-box wow slideInUp animate__animated_title">
        <div
          class="sort"
          v-for="(item, index) in senceArr"
          :key="index"
          @mouseenter="enterSence(item.id)"
          @mouseleave="leaveSence(item.id)"
        >
          <p>{{ item.title }}</p>
          <span v-for="(ele, index) in item.descList" :key="index">
            <img
              src="@/assets/home/products/sence-icon-hover.png"
              alt=""
              style="margin-right: 10px; width: 22px; height: 22px"
              v-if="senceId == item.id"
            />
            <img
              src="@/assets/home/products/sence-icon.png"
              alt=""
              style="margin-right: 10px; width: 22px; height: 22px"
              v-else
            />
            {{ ele }}</span
          >
          <img
            :src="item.imgActive"
            alt=""
            class="bg-pos"
            v-if="senceId == item.id"
          />
          <img :src="item.img" alt="" class="bg-pos" v-else />
        </div>
      </div>
    </div>
    <!-- <div class="home-partners">
      <div class="home-partners-title">合作品牌</div>
      <div class="home-partners-box">
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
        <div class="sort"></div>
      </div>
    </div> -->

    <div class="home-service">
      <div class="title wow slideInUp animate__animated_bg">
        立即免费体验区块链应用
      </div>
      <div class="desc wow slideInUp animate__animated_bg">
        还有问题？请联系我们获取更多信息
      </div>
      <div style="display: flex" class="wow slideInUp animate__animated_bg">
        <div class="service-btn" @click="goUrl('/contact')">合作咨询</div>
        <!-- <div class="contact-btn" @click="customerShow = true">快速解疑</div> -->
        <!-- <div class="contact-btn">前往论坛</div> -->
      </div>
    </div>
    <Customer v-show="customerShow" @closeDialog="closeDialog"></Customer>
  </div>
</template>

<script>
import Customer from "../../../components/commonPopup/Customer.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Customer,
  },
  data() {
    return {
      customerShow: false,
      activeName: "first",
      show: true,
      current: 0,
      diversifyArr: [
        {
          imgUrl: require("@/assets/home/products/sence-bg.png"),
          imgBgUrl: require("@/assets/home/products/sence-selected-bg.png"),
          title: "场景融合",
          desc: "结合各个行业具体业务场景，以区块链技术为基础，通过XaaS服务、人工智能、大数据分析等技术，为供应链金融、供应链溯源、版权交易、数据存证、财务审计等各个场景提供全面的区块链技术接入。",
        },
        {
          imgUrl: require("@/assets/home/products/safe-bg.png"),
          imgBgUrl: require("@/assets/home/products/safe-selected-bg.png"),
          title: "高度安全",
          desc: "使用高度安全的网络传输、数据存储技术，提供丰富的密码学工具，支持区块链网络分级保护、全网监控、多方治理、实时告警等功能强大的运维系统，让企业数据得到全方位的安全保护。",
        },
        {
          imgUrl: require("@/assets/home/products/flexible-bg.png"),
          imgBgUrl: require("@/assets/home/products/flexible-selected-bg.png"),
          title: "性能优越",
          desc: "在满足安全性的前提下，通过高速共识算法，P2P网络优化，交易流程设计，高性能密码学工具等多领域技术的支撑，提供高达10000+ TPS的交易速度，满足企业高交易吞吐量业务的需求。",
        },
        {
          imgUrl: require("@/assets/home/products/superior-bg.png"),
          imgBgUrl: require("@/assets/home/products/superior-selected-bg.png"),
          title: "扩展灵活",
          desc: "模块化可插拔的系统架构，标准模板化的业务搭建，天然内置的跨链特性，安全可靠的预言机网络，让企业可以根据需求灵活的调整区块链技术在业务中的应用，随时拓展新的业务领域，拓宽业务边界。",
        },
      ], //多样化区块链服务
      innovateId: null,
      innovateArr: [
        {
          img: require("@/assets/home/innovate/innovate-01.png"),
          imgActive: require("@/assets/home/innovate/innovate-01-active.png"),
          title: "XaaS云服务",
          desc: "围绕Web 3.0业务的一站式服务平台",
          path: "/xaas",
        },
        {
          img: require("@/assets/home/innovate/innovate-02.png"),
          imgActive: require("@/assets/home/innovate/innovate-02-active.png"),
          title: "SealABC",
          desc: "高速共识算法，硬件密码学组件，组件化积木式搭建方式",
          path: "/sealabc",
        },
      ], //自主创新
      senceArr: [
        {
          title: "政务",
          descList: ["政务公开透明", "部门协作高效", "数据安全共享"],
          img: require("@/assets/home/products/01.png"),
          imgActive: require("@/assets/home/products/01_selected.png"),
          id: 1,
        },
        {
          title: "金融",
          descList: ["价值传递安全", "流转清晰明了", "审计高效合规"],
          img: require("@/assets/home/products/02.png"),
          imgActive: require("@/assets/home/products/02_selected.png"),
          id: 2,
        },
        {
          title: "版权",
          descList: ["确权效率提高", "交易安全可靠", "维权有证可查"],
          img: require("@/assets/home/products/03.png"),
          imgActive: require("@/assets/home/products/03_selected.png"),
          id: 3,
        },
        {
          title: "农业",
          descList: ["产品防伪溯源", "物流效率提高", "分销环节优化"],
          img: require("@/assets/home/products/04.png"),
          imgActive: require("@/assets/home/products/04_selected.png"),
          id: 4,
        },
        {
          title: "医疗健康",
          descList: ["信息高度安全", "投保理赔高效", "医疗数据共享"],
          img: require("@/assets/home/products/05.png"),
          imgActive: require("@/assets/home/products/05_selected.png"),
          id: 5,
        },
        {
          title: "物联网",
          descList: ["网络安全增强", "隐私全面保护", "业务高度可靠"],
          id: 6,
          img: require("@/assets/home/products/06.png"),
          imgActive: require("@/assets/home/products/06_selected.png"),
        },
      ],
      senceId: null,
      tabPosition: "left",
      tabArr: [
        {
          id: 0,
          name: "NFT 数字藏品",
        },
        {
          id: 1,
          name: "DID 身份认证",
        },
        {
          id: 2,
          name: "域名服务",
        },
        {
          id: 3,
          name: "NFT 票务",
        },
      ],
      tabIndex: 0,
      contentArr: [
        {
          questionDesc:
            "数字藏品活动期间存在恶意刷取优惠福利的行为，并且流量突发容易遭受DDoS攻击，黑客容易获取网站核心数据和用户隐私数据，导致数据被篡改、被删除等风险；且用户在抢购数字资产时，由于大量玩家涌入，业务高并发，常常会出现应用无法访问，无法支付等崩溃的情况。",
          answerDesc:
            "数字资产管理平台采用高可用、大并发的系统架构，通过DDoS高防防大流量攻击，提升访问安全，基于数字资产链，仅需API对接快速完成平台开发，实现数字内容全生命周期管理。",
          path: "/nft",
          iconArr: [
            {
              desc: "活动安全存疑",
              imgUrl: require("@/assets/home/introduce/icon-1.png"),
            },
            {
              desc: "系统体验差",
              imgUrl: require("@/assets/home/introduce/icon-2.png"),
            },
            {
              desc: "业务高并发",
              imgUrl: require("@/assets/home/introduce/icon-3.png"),
            },
          ],
        },
        {
          questionDesc:
            "互联网用户的身份的特征和行为暴露在网络中，被各个平台无视相关规定，肆意收集身份相关的行为信息并滥用这些信息；且不同网站自己用的身份系统（及账户对应的数据）之间是不互通的，不同的中心化网站上有一套自己的身份系统，每个都需要用户重新注册一个账户。",
          answerDesc:
            "利用区块链密码学等技术助力身份标识脱敏，避免敏感数据盗用。全链路加密和存储身份信息，使得数据使用可用不可见；DID可以在各种平台上运行，包括不同的区块链，利用分散身份协议的用户，可以安全地管理用户个人的数字身份，将其存储在手机或云服务上，并有一个私人密钥，只允许经过验证的用户访问。",
          path: "/did",
          iconArr: [
            {
              desc: "个人隐私保护难题",
              imgUrl: require("@/assets/home/introduce/icon-4.png"),
            },
            {
              desc: "中心化身份容错率低",
              imgUrl: require("@/assets/home/introduce/icon-5.png"),
            },
            {
              desc: "系统之间数据不互通",
              imgUrl: require("@/assets/home/introduce/icon-6.png"),
            },
          ],
        },
        {
          questionDesc:
            "全球传统域名注册市场规模约过多，优质域名早已经被瓜分完毕，后来者难以注册并拥有优质的域名，只能通过高价购买获得；且域名由注册局进行管理，用户通过中介机构（域名注册服务商）进行注册，若是域名未及时续费或是出现其他情况，域名则可能会被收回。",
          answerDesc:
            "区块链域名系统的优质域名暂未被抢注多少，购买费用极低，且实现了“一次购买，终身使用”，用户可以无限注册域名进行转售；智能合约可以取代多数注册机构的角色及流程。任何机构与个人可以依据注册机构的规则来创建子域名。而且是一次购买，终身使用。",
          path: "/ens",
          iconArr: [
            {
              desc: "传统域名市场饱和",
              imgUrl: require("@/assets/home/introduce/icon-7.png"),
            },
            {
              desc: "传统域名注册频繁",
              imgUrl: require("@/assets/home/introduce/icon-8.png"),
            },
            {
              desc: "域名管理中心化",
              imgUrl: require("@/assets/home/introduce/icon-9.png"),
            },
          ],
        },
        {
          questionDesc:
            "传统票务受限于监管管控，内票发行渠道较少，二级票务市场的转售商哄抬票价，黄牛票贩则通过网络买下大部分门票并高价转售，对渠道方以及购票用户带来不可估量的价值损失。",
          answerDesc:
            "门票存储与区块链上，任何人都能在区块链账本中追踪交易，活动组织方可以更好地控制一级和二级市场的门票出售；可借助智能合约这个运行于区块链的程序对转售价格和费用设限。智能合约是确定性程序，会在达到某些条件时自主执行特定任务。组织方通过设置规则，即可决定如何分配二级门票销售的版权费。只要门票出售给新的所有者，就会触发版权费支付。",
          path: "/ticketing",
          iconArr: [
            {
              desc: "票务发行受管控",
              imgUrl: require("@/assets/home/introduce/icon-10.png"),
            },
            {
              desc: "票务易伪造",
              imgUrl: require("@/assets/home/introduce/icon-11.png"),
            },
            {
              desc: "纸质票务易丢失",
              imgUrl: require("@/assets/home/introduce/icon-12.png"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    closeDialog(value) {
      this.customerShow = value;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    removeActive($event, id) {
      this.current = id;
      this.show = true;
    },
    enter(index) {
      this.innovateId = index;
    },
    leave() {
      this.innovateId = null;
    },
    enterSence(id) {
      this.senceId = id;
    },
    leaveSence() {
      this.senceId = null;
    },
    goUrl(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
    enterSolve(id) {
      this.tabIndex = id;
      var aa = document.getElementById("content");
      // console.log(aa);
      aa.style.transform = `translate3d(0px, -${id * 347}px, 0px)`;
      aa.style.transitionDuration = "300ms";
      var cc = document.getElementById("cc");
      cc.style.transform = `translateY(${id * 54}px)`;
      cc.style.transition = `transform 500ms cubic-bezier(0.48, 0.33, 0.24, 0.95) 0ms`;
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate-other {
  -moz-animation-duration: 450ms;
  -webkit-animation-duration: 450ms;
  animation-duration: 450ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0ms;
}
.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@keyframes fadeInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.animate__animated {
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animate__animated_title {
  animation: fadeInRight 2s ease-in-out both;
}
.animate__animated_content {
  animation: fadeInRight 3s ease-in-out both;
}
.mouse {
  width: 220px !important;
}
.swiper-slide {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.48, 0.33, 0.24, 0.95);
  transition-timing-function: cubic-bezier(0.48, 0.33, 0.24, 0.95);
}
.active {
  opacity: 1;
}
.overflow-hidden {
  overflow: hidden;
  height: 347px;
}
.Home_active__sqoHq {
  background: linear-gradient(
    90deg,
    #5c90fe 11.73%,
    #466cf5 56.21%,
    #4265f3 69.15%,
    #3853ef 87.49%,
    #2e42eb 100.05%
  );
  border-radius: 2px;
  opacity: 1;
}
.w-\[212px\] {
  width: 220px;
}
.h-\[52px\] {
  height: 54px;
}
.left-\[12px\] {
  left: 30px;
}
.top-\[16px\] {
  top: 30px;
}
.absolute {
  position: absolute;
}
</style>