<template>
  <div class="footer-box">
    <div class="footer-menu">
      <footer class="footer">
        <div class="footer-left">
          <div>
            <div class="nav-img">
              <img src="@/assets/footer/footer-logo.png" alt="" />
            </div>
          </div>
        </div>
        <div class="footer-right">
          <ul>
            <li>关于公司</li>
            <li @click="toTechnology('/about')">
              <span>公司简介</span>
            </li>
            <li @click="toTechnology('/contact')">
              <span>合作咨询</span>
            </li>
            <li @click="toHome(3)">
              <span>问题留言</span>
            </li>
          </ul>
          <ul>
            <li>核心产品</li>
            <li @click="toTechnology('/xaas')">
              <span>XaaS云服务</span>
            </li>
            <li @click="toTechnology('/sealabc')">
              <span>SealABC</span>
            </li>
          </ul>
          <ul>
            <li>解决方案</li>
            <div class="aa">
              <div class="wrap-menu">
                <div @click="toTechnology('/government')">政务</div>
                <div @click="toTechnology('/finance')">金融</div>
                <div @click="toTechnology('/agriculture')">农业</div>
                <div @click="toTechnology('/copyright')">版权</div>
              </div>
              <div class="wrap-menu">
                <div @click="toTechnology('/healthy')">医疗健康</div>
                <div @click="toTechnology('/internet')">物联网</div>
                <div @click="toTechnology('/entertainment')">文娱IP</div>
                <div @click="toTechnology('/ticketing')">票务服务</div>
              </div>
              <div class="wrap-menu">
                <div @click="toTechnology('/did')">DID身份</div>
                <div @click="toTechnology('/nft')">数字藏品</div>
                <div @click="toTechnology('/ens')">域名服务</div>
              </div>
            </div>
          </ul>
        </div>
      </footer>
    </div>
    <div class="copy-right">
      <span>京ICP备19056943号</span>
      <span>Copyright © 2020 dreamchains.cn All Rights Reserved.</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async toTechnology(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-box {
  width: 100%;
  height: 416px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #272e40;
  color: rgba(255, 255, 255, 0.6);
  padding-top: 100px;
  box-sizing: border-box;
}
.footer-menu {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  background-color: #272e40;
  color: rgba(255, 255, 255, 0.6);
}
.footer {
  width: 1180px;
  height: 100%;
  display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 84px;
  &-right {
    // flex-basis: 52%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding-left: 72px;
    box-sizing: border-box;
    .aa {
      display: flex;
      height: 112px;
      // width: 230px;
      .wrap-menu {
        padding-right: 30px;
        cursor: pointer;
      }
      .wrap-menu:nth-child(3n-1) {
        width: 64px;
        text-align: left;
      }
      .wrap-menu div {
        margin-bottom: 12px;
      }
      .wrap-menu div:hover {
        color: #ffffff;
      }
    }
    ul {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.6);
      margin-right: 88px;
    }
    li {
      list-style: none;
      margin-bottom: 12px;
    }
    li:first-child {
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      margin-bottom: 24px;
    }
    li > span {
      cursor: pointer;
    }
    li > span > a {
      color: rgba(0, 0, 0, 0.6);
    }
    // li > span > a:active {
    //   color: rgba(0, 0, 0, 1);
    // }
    li > span:hover {
      // text-decoration-line: underline;
      color: #ffffff;
    }
    // li > span:active {
    //   color: rgba(0, 0, 0, 1);
    // }
  }
  &-left {
    display: flex;
    display: flex;
    padding-right: 72px;
    box-sizing: border-box;
    height: 152px;
    border-right: 1px solid rgba(255, 255, 255, 0.06);
    .nav-img {
      img {
        vertical-align: bottom;
        width: 104px;
        height: auto;
      }
    }
  }
}
.copy-right {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #272e40;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  color: rgba(255, 255, 255, 0.4);
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  span:first-child {
    margin-right: 10px;
  }
}
// @media screen and (min-width: 500px) and (max-width: 750px) {
//   .footer-menu {
//     height: 240px;
//   }
//   .footer {
//     display: inline-block;
//     padding: 0;
//     &-left {
//       .nav-img {
//         margin-top: 30px;
//       }
//       justify-content: initial;
//       padding-left: 32px;
//       .seor-info {
//         margin-top: 26px;
//       }
//     }
//     &-right {
//       display: block;
//       padding-left: 32px;
//       margin-top: 48px;
//       padding-bottom: 32px;
//       ul {
//         margin-top: 32px;
//         height: auto;
//       }
//     }
//   }
// }
// @media screen and (max-width: 500px) {
//   .footer-menu {
//     height: 240px;
//   }
//   .footer {
//     display: inline-block;
//     padding-left: 32px;
//     &-left {
//       .nav-img {
//         margin-top: 30px;
//       }
//       justify-content: initial;
//       padding-left: 32px;
//       .seor-info {
//         margin-top: 26px;
//       }
//     }
//     &-right {
//       display: block;
//       padding-left: 32px;
//       margin-top: 48px;
//       padding-bottom: 32px;
//       ul {
//         margin-top: 32px;
//         height: auto;
//       }
//     }
//   }
// }

// @media screen and (min-width: 750px) and (max-width: 1220px) {
//   .footer {
//     padding: 0 50px;
//     &-left {
//       flex-basis: 32%;
//     }
//     &-right {
//       flex-basis: 60%;
//     }
//   }
// }
</style>