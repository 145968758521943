<template>
  <div class="solve">
   <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePc :programmeArr="programmeArr"></ProgrammePc>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <Programme :programmeArr="programmeArr"></Programme>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import Programme from "./modules/Programme.vue";
import ProgrammePc from "./modules/ProgrammePc.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    Programme,
    ProgrammePc,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 医疗健康",
        desc: " 将个人以及医院、药企、科研机构、保险机构等相关信息和医疗健康数据上链共享，帮助个人获得精准医疗和保险服务，协助药企、科研机构进行生产、研发、防伪溯源，助力医疗健康产业形成生态闭环  ",
        imgUrl: require("@/assets/solution/healthy/banner.png"),
      },
       bannerArrMobile: {
        title: "区块链+ 医疗健康",
        desc: " 将个人以及医院、药企、科研机构、保险机构等相关信息和医疗健康数据上链共享，帮助个人获得精准医疗和保险服务，协助药企、科研机构进行生产、研发、防伪溯源，助力医疗健康产业形成生态闭环  ",
        imgUrl: require("@/assets/solution/healthy/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "数据孤岛",
          desc: "医疗行业缺乏一套记录患者完整医疗信息的数据系统，个人健康信息不连续。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "数据隐私",
          desc: "数据互通支配权在管理端，面临隐私、安全问题，以及完整性问题；且数据分类模糊、数据泄露风险高等问题。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "数据时效差",
          desc: "监管存在数据质量差、时效差等问题各方面挑战。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/healthy/healthy-icon-1.png"),
            desc: "医疗数据真实",
          },
          {
            imgUrl: require("@/assets/solution/healthy/healthy-icon-2.png"),
            desc: "个人信息私密",
          },
          {
            imgUrl: require("@/assets/solution/healthy/healthy-icon-3.png"),
            desc: "疾病治疗精准",
          },
          {
            imgUrl: require("@/assets/solution/healthy/healthy-icon-4.png"),
            desc: "药品安全可靠",
          },
        ],
        title: "提供安全、可靠、隐私的个人医疗大数据平台。",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/healthy/healthy.png"),
          name: "医疗健康大数据",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>