<template>
  <div>
    <div class="banner">
      <div class="header-image">
        <img src="@/assets/mobile/banner-bg.png" alt="" />
        <div class="banner-image">
          <img :src="coreArr.bannerImage" alt="" />
        </div>
      </div>
      <div class="banner-box">
        <div class="banner-title">
          {{ coreArr.bannerTitle }}
        </div>
        <div class="banner-desc">
          {{ coreArr.bannerDesc }}
        </div>
      </div>
    </div>
    <div class="core-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img :src="coreArr.bannerImage" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">
              {{ coreArr.bannerTitle }}
            </div>
            <div class="content">
              {{ coreArr.bannerDesc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce wow slideInUp animate__animated_title">
      <div class="title">{{ coreArr.introduceTitle }}</div>
      <div class="desc">
        {{ coreArr.introduceDesc }}
      </div>
      <div class="open-btn" v-if="coreArr.trialStatus == 1">
        <a
          style="color: #fff"
          href="https://xaas.dreamchains.cn/"
          target="_blank"
          >免费试用
        </a>
      </div>
      <div class="disable-btn" v-else>即将上线</div>
    </div>
    <div class="introduce-ipad">
      <div class="introduce-ipad-box">
        <div class="left">
          <img :src="coreArr.introduceImage" alt="" />
        </div>
        <div class="right wow slideInUp animate__animated_title">
          <div class="title">{{ coreArr.introduceTitle }}</div>
          <div class="desc">
            {{ coreArr.introduceDesc }}
          </div>
          <div class="open-btn" v-if="coreArr.trialStatus == 1">
            <a
              style="color: #fff"
              href="https://xaas.dreamchains.cn/"
              target="_blank"
              >免费试用
            </a>
          </div>
          <div class="disable-btn" v-else>即将上线</div>
        </div>
      </div>
    </div>
    <div class="technology">
      <div class="technology-title wow slideInUp animate__animated_title">
        平台特点
      </div>
      <div class="icon-box wow slideInUp animate__animated_title">
        <div
          class="icon-ipad"
          v-for="(item, index) in coreArr.technologyArr"
          :key="index"
        >
          <div class="icon">
            <div>
              <img :src="item.imgUrl" alt="" />
            </div>
            <div>
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="system wow slideInUp animate__animated_title">
      <div class="system-title">系统架构</div>
      <div class="system-box">
        <img :src="coreArr.systemImage" alt="" />
      </div>
    </div>
    <div class="products">
      <div class="products-title wow slideInUp animate__animated_title">
        产品优势
      </div>
      <div class="icon-box wow slideInUp animate__animated_title">
        <div
          class="icon-wrap"
          v-for="(item, index) in coreArr.productsArr"
          :key="index"
        >
          <div class="icon">
            <img
              :src="item.imgUrl"
              alt=""
              :class="coreArr.trialStatus == 2 ? 'seal-img' : 'xaas-img'"
            />
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="icon wow slideInUp animate__animated_bg">
        <div class="title">区块链平台适用</div>
        <div class="desc">
          如果你对区块链平台感兴趣，我们提供全功能免费试用版本供你体验。
        </div>
        <div class="btn contact" v-if="coreArr.trialStatus == 1">
          <a
            style="color: #fff"
            href="https://xaas.dreamchains.cn/"
            target="_blank"
            >立即试用
          </a>
        </div>
        <div class="disable-btn" v-else>即将上线</div>
      </div>
      <div class="icon wow slideInUp animate__animated_bg">
        <div class="title">联系我们</div>
        <div class="desc">
          如果您对以上服务有需求，可以联系我们，我们会第一时间与您联系。
        </div>
        <div class="btn contact" @click="goUrl('/contact')">联系我们</div>
      </div>
    </div>
    <div class="ipad-info">
      <div class="icon-box wow slideInUp animate__animated_bg">
        <div class="icon-ipad">
          <div class="icon">
            <div class="title">区块链平台适用</div>
            <div class="desc">
              如果你对区块链平台感兴趣，我们提供全功能免费试用版本供你体验。
            </div>
            <div class="btn contact" v-if="coreArr.trialStatus == 1">
              <a
                style="color: #fff"
                href="https://xaas.dreamchains.cn/"
                target="_blank"
                >立即试用
              </a>
            </div>
            <div class="btn disable" v-else>即将上线</div>
          </div>
        </div>
        <div class="icon-ipad">
          <div class="icon">
            <div class="title">联系我们</div>
            <div class="desc">
              如果您对以上服务有需求，可以联系我们，我们会第一时间与您联系。
            </div>
            <div class="btn contact" @click="goUrl('/contact')">联系我们</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  components: {},
  props: {
    coreArr: Object,
  },
  data() {
    return {};
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },

  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.banner {
  width: 100%;
  height: 480px;
  background: #f5f7fc;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 480px;
    img {
      width: 100%;
      height: 345px;
    }
    .banner-image {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .banner-box {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 30px;
    box-sizing: border-box;
    font-family: "Helvetica Neue";
    font-style: normal;
    .banner-title {
      font-weight: 700;
      font-size: 23px;
      line-height: 100%;
      text-align: center;
      width: 100%;
      color: #1d2c67;
      margin-top: 60px;
    }
    .banner-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 45px */
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(29, 44, 103, 0.8);
      margin-top: 20px;
    }
    .contact-btn {
      width: 100%;
      height: 45px;
      background: #3d5dcc;
      border-radius: 2px;
      line-height: 45px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #e8eaf4;
      margin-top: 20px;
    }
  }
}
.introduce {
  display: block;
  padding: 40px 15px 35px;
  box-sizing: border-box;
  .title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #1d2c67;
  }
  .desc {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: rgba(29, 44, 103, 0.6);
    margin: 15px 0;
  }
  .open-btn {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #3d5dcc;
    border-radius: 2px;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #e8eaf4;
  }
}
.introduce-ipad {
  display: none;
}
.technology {
  width: 100%;
  height: 728px;
  background: url("../../../assets/mobile/skew-bg.png") no-repeat 100% 100%;
  background-position: left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  box-sizing: border-box;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
  }
  .icon-box {
    margin-top: 20px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    .icon {
      width: 100%;
      height: 132px;
      display: flex;
      padding: 0px 15px 0 30px;
      background-color: #fff;
      box-sizing: border-box;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #15255b;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: rgba(21, 37, 91, 0.8);
        margin-top: 14px;
      }
    }
  }
}
.system {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0 32px;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  &-box {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.products {
  width: 100%;
  background: url("../../../assets/mobile/skew-bg.png") no-repeat 100% 100%;
  background-position: left top;
  background-size: 100% 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 15px 60px;
  box-sizing: border-box;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
  }
  .icon-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    .icon-wrap {
      width: 50%;
      box-sizing: border-box;
      .icon {
        width: 100%;
        height: 130px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;
        img {
          width: 45px;
          height: 45px;
          margin: 0 auto;
          display: block;
        }
        .desc {
          font-family: "Helvetica Neue";
          font-style: normal;
          // font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: #15255b;
          margin-top: 16px;
        }
      }
    }
    .icon-wrap:nth-child(2n + 1) {
      padding-right: 7.5px;
    }
    .icon-wrap:nth-child(2n) {
      padding-left: 7.5px;
    }
  }
}
.info {
  display: block;
  width: 100%;
  background: url("../../../assets/mobile/core-bg.png") no-repeat 100% 100%;
  background-position: top left;
  padding: 42px 15px 30px;
  box-sizing: border-box;
  margin-top: -40px;
  .icon {
    .title {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 35px;
    }
    .desc {
      width: 100%;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: rgba(255, 255, 255, 0.8);
      margin: 10px 0 15px;
    }
    .btn {
      width: 100%;
      height: 45px;
      line-height: 45px;
      background: #ffffff;
      border-radius: 2px;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #1d2c67;
      a {
        color: #1d2c67 !important;
      }
    }
  }
}
.ipad-info {
  display: none;
}
.disable-btn {
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #868eaa;
  border-radius: 2px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #e8eaf4;
}
.core-title {
  display: none;
}
@media screen and (min-width: 575px) and (max-width: 1180px) {
  .introduce-ipad {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 15px 35px;
    box-sizing: border-box;
    &-box {
      width: 100%;
      justify-content: space-between;
      display: flex;
      .left {
        width: 30%;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .right {
        flex: 1;
        font-family: "Helvetica Neue";
        font-style: normal;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 30px;
        box-sizing: border-box;
        .title {
          font-weight: 700;
          font-size: 20px;
          line-height: 100%;
          color: #1d2c67;
          margin-bottom: 24px;
        }
        .desc {
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.6);
        }
        .open-btn {
          font-family: "Helvetica Neue";
          font-style: normal;
          width: 132px;
          height: 44px;
          background: #3d5dcc;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          color: #ffffff;
          text-align: center;
          margin-top: 40px;
          cursor: pointer;
        }
        .open-btn:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            #1a61ea;
        }
        .border-btn {
          font-family: "Helvetica Neue";
          font-style: normal;
          width: 132px;
          height: 44px;
          border: 1px solid #3d5dcc;
          border-radius: 2px;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          color: #3d5dcc;
          text-align: center;
          margin-top: 40px;
          cursor: pointer;
          box-sizing: border-box;
          margin-left: 24px;
        }
        .disable-btn {
          width: 132px;
          height: 44px;
          background: #868eaa;
          border-radius: 2px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          color: #e8eaf4;
          margin-top: 40px;
        }
      }
    }
  }
  .introduce {
    display: none;
  }
  .technology {
    height: auto;
    padding: 60px 0 50px;
    box-sizing: border-box;
    .icon-box {
      display: flex;
      flex-wrap: wrap;
    }
    .icon-ipad {
      width: 50%;
    }
    .icon-ipad:nth-child(2n) {
      padding-left: 7.5px;
      box-sizing: border-box;
    }
    .icon-ipad:nth-child(2n + 1) {
      padding-right: 7.5px;
      box-sizing: border-box;
    }
  }
  .info {
    display: none;
  }
  .ipad-info {
    width: 100%;
    height: auto;
    background: url("../../../assets/core/sealabc/core-ipad-bg.png") no-repeat
      100% 100%;
    background-position: top left;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 105px 15px 30px;
    box-sizing: border-box;
    margin-top: -80px;
    .icon-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .icon-ipad {
        width: 50%;
      }
      .icon {
        width: 100%;
        height: auto;
        padding: 30px 15px 20px;
        background: url("../../../assets/core/sealabc/core-little-bg.png")
          no-repeat 100% 100%;
        box-sizing: border-box;
        position: relative;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #1d2c67;
        }
        .desc {
          width: 90%;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.8);
          margin-top: 10px;
        }
        .btn {
          width: 132px;
          height: 44px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          color: #e8eaf4;
          cursor: pointer;
          border-radius: 2px;
          margin-top: 20px;
        }
        .disable {
          background: #868eaa;
        }
        .contact {
          background: #3d5dcc;
        }
        .contact:hover {
          background-color: #344fad;
        }
      }
      .icon-ipad:nth-child(2n + 1) {
        padding-right: 7.5px;
        box-sizing: border-box;
      }
      .icon-ipad:nth-child(2n) {
        padding-left: 7.5px;
        box-sizing: border-box;
      }
    }
  }
  .banner {
    display: none;
  }
  .core-title {
    display: block;
    width: 100%;
    height: 268px;
    background: rgba(245, 247, 252, 0.4);
    position: relative;
    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 25%);
      z-index: 0;
      height: 268px;
      img {
        width: 100%;
        height: 268px;
      }
      .right {
        position: absolute;
        top: 0;
        right: -25%;
        width: 50%;
        display: flex;
        align-items: center;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .banner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: calc(100vw - 25%);
      height: 268px;
      z-index: 2;
      background: transparent;
    }
    .banner-box {
      width: calc(100vw - 25%);
      height: 268px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        width: 100%;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 25px;
          color: #1d2c67;
        }
        .content {
          width: 75%;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.8);
          margin-top: 20px;
        }
      }
    }
  }
}
</style>