<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePcOther :programmeArr="programmeArr"></ProgrammePcOther>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <ProgrammeOther :programmeArr="programmeArr"></ProgrammeOther>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import ProgrammeOther from "./modules/ProgrammeOther.vue";
import ProgrammePcOther from "./modules/ProgrammePcOther.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    ProgrammeOther,
    ProgrammePcOther,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ DID身份认证",
        desc: " 通过区块链安全的存储个人和企业用户的身份信息，并在保护隐私的情况下为用户、用户信息提供方、用户信息使用方提供身份标识和信息交互，使得企业和机构可以更合规的使用和管理用户身份信息及数据授权，而身份信息的真实性得到了极大的保障。",
        imgUrl: require("@/assets/solution/did/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ DID身份认证",
        desc: " 通过区块链安全的存储个人和企业用户的身份信息，使企业和机构可以更合规的使用和管理用户身份信息及数据授权，身份信息的真实性得到极大的保障。",
        imgUrl: require("@/assets/solution/did/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "个人隐私保护难题",
          desc: "互联网用户的身份的特征和行为暴露在网络中，被各个平台无视相关规定，肆意收集身份相关的行为信息并滥用这些信息。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "中心化身份容错率低",
          desc: "传统由中心化签发认证身份的模式信任成本高，且容易出现中心单点失效问题，容错率低。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "系统之间数据不互通",
          desc: "不同的中心化网站上有一套自己的身份系统，每个都需要用户重新注册一个账户。而不同网站自己用的身份系统（及账户对应的数据）之间是不互通的。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/did/did-icon-1.png"),
            desc: "利用密码学等技术助力身份标识脱敏，避免敏感数据盗用。全链路加密和存储身份信息，使得数据使用可用不可见。",
            title: "强数据隐私保护",
          },
          {
            imgUrl: require("@/assets/solution/did/did-icon-2.png"),
            desc: "身份等信息在基于区块链的分布式系统上永久留存，由身份所有者管理和授权，身份使用方在被授权的情况下使用而无法作其他用途。",
            title: "分布式身份系统",
          },
          {
            imgUrl: require("@/assets/solution/did/did-icon-3.png"),
            desc: "利用生物识别、移动终端、区块链和密码学等多种手段，实现一个便捷的联合可控的数字身份管理体系。",
            title: "领先的身份管理",
          },
          {
            imgUrl: require("@/assets/solution/did/did-icon-4.png"),
            desc: "自我主权身份相比于基于联盟链的分布式身份认证和授权更多强调用户身份的自主权和身份数据的控制权。区块链提供分布式的信任环境，是实现自我主权身份的必要技术。",
            title: "实现用户数据自治",
          },
        ],
        title: "打破传统的数字身份存在诸多问题",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/did/did.png"),
          name: "身份认证",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>