<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePcOther :programmeArr="programmeArr"></ProgrammePcOther>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <ProgrammeOther :programmeArr="programmeArr"></ProgrammeOther>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import ProgrammeOther from "./modules/ProgrammeOther.vue";
import ProgrammePcOther from "./modules/ProgrammePcOther.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    ProgrammeOther,
    ProgrammePcOther,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 物联网",
        desc: " 通过多级授权认证、分区共识、链网架构等技术解决方案，为车联网、智能家居等多种物联网场景构筑更加安全的IoT网络，同时提供企业级的个人隐私和设备保护。",
        imgUrl: require("@/assets/solution/internet/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 物联网",
        desc: " 通过多级授权认证、分区共识、链网架构等技术解决方案，为车联网、智能家居等多种物联网场景构筑更加安全的IoT网络，同时提供企业级的个人隐私和设备保护。",
        imgUrl: require("@/assets/solution/internet/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "设备数量显著增长",
          desc: "终端使用者结构日益纷繁的市场中，无法保证数据真实、安全、互通。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "上链数据无法验证",
          desc: "很多数据通过人工录入或直接从中心化数据库提取，难免存在有意或无意的数据错误，难以做到链下场景和链上数据的深度绑定。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "设备无法确保可靠",
          desc: "物联网设备数量居多且分散，因为流氓软件和设备遭篡改的后果十分严重。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/internet/internet-icon-1.png"),
            desc: "每笔交易都会被记录下来，放入一个数据块中，并添加到一个无法更改的安全、不可变的数据链中，且只能进行添加。",
            title: "树立信任，信息安全",
          },
          {
            imgUrl: require("@/assets/solution/internet/internet-icon-2.png"),
            desc: "区块链可在多个利益相关方之间创建集中可信的共享数据集，从而取代中间商。去中介以及整个价值链交易自动化无疑将带来成本效益的提升",
            title: "削减成本，去中心化",
          },
          {
            imgUrl: require("@/assets/solution/internet/internet-icon-3.png"),
            desc: "现有企业可利用区块链与物联网技术结合来尽可能减少收入损失，区块链还可实现原本不可行或不可扩展的功能，从而充分释放物联网的价值。",
            title: "提升收入，释放价值",
          },
          {
            imgUrl: require("@/assets/solution/internet/internet-icon-4.png"),
            desc: "区块链与物联网结合应用还可确保产品在整个生命周期的质量和正品属性，帮助企业维护自身品牌和声誉。",
            title: "降低风险，维护品牌",
          },
        ],
        title: "让IoT网络更安全，有效保护设备和个人隐私",
      },
      frameworkArr: [
         {
          imgUrl: require("@/assets/solution/internet/internet-1.png"),
          name: "车联网",
          grade: "first",
        },
        {
          imgUrl: require("@/assets/solution/internet/internet-2.png"),
          name: "智能家居",
          grade: "second",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>