<template>
  <div class="solve-dialog-box">
    <div class="solve-dialog">
      <div class="programme-list">
        <div class="list-title">
          <div class="solve-logo">
            <img src="@/assets/nav/solve_logo.png" alt="" />
            <div>
              <div class="programme-title">解决方案</div>
              <div class="sub-title">
                基于区块链架构服务助力各行业价值互联网的连接，全流程场景化区块链解决方案
              </div>
            </div>
          </div>
        </div>
        <div class="list-box">
          <div
            class="router-box"
            v-for="(item, index) in routerList"
            :key="index"
            @click="goUrl(item.path)"
          >
            <div class="name">
              <span>{{ item.name }}</span>
              <span class="arrow"></span>
            </div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="solve-mask" @mouseenter="close()"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: [
        {
          name: "政务",
          desc: "政务公开透明，提高服务效率，精细行政管理。",
          path: "/government",
        },
        {
          name: "金融",
          desc: "完善金融网络，助推数字金融高质量发展。",
          path: "/finance",
        },
        {
          name: "农业",
          desc: "农产品防伪溯源，助力农业生态建设。",
          path: "/agriculture",
        },
        {
          name: "版权",
          desc: "用区块链技术构建更加可信安全的数字版权体系。",
          path: "/copyright",
        },
        {
          name: "医疗健康",
          desc: "提供安全、可靠、隐私的个人医疗大数据平台。",
          path: "/healthy",
        },
        {
          name: "物联网",
          desc: "让IoT网络更安全，有效保护设备和个人隐私。",
          path: "/internet",
        },
        {
          name: "文娱",
          desc: "IP资产数字化，助力文化娱乐IP产业生态发展。",
          path: "/entertainment",
        },
        {
          name: "票务系统",
          desc: "助力解决传统票务低效问题，变革票务行业格局。",
          path: "/ticketing",
        },
        {
          name: "DID身份",
          desc: "打破传统的数字身份存在诸多问题。",
          path: "/did",
        },
        {
          name: "NFT 数字藏品",
          desc: "用户自由定制构，建业务场景。",
          path: "/nft",
        },
        {
          name: "域名服务",
          desc: "打破传统市场，Web 3.0 域名满足用户新需求。",
          path: "/ens",
        },
      ],
    };
  },
  methods: {
    goUrl(path) {
      this.close();
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
    close() {
      this.$emit("closeSlove", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.solve-dialog-box {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  .solve-dialog {
    width: 100%;
    height: 370px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(61, 93, 204, 0.1);
    display: flex;
    justify-content: center;
    .programme-list {
      width: 1180px;
      height: 370px;
      .list-title {
        padding: 21px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        cursor: auto;
        .solve-logo {
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }
        .programme-title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          color: #1d2c67;
        }
        .sub-title {
          margin-top: 12px;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;
          color: rgba(29, 44, 103, 0.4);
        }
      }
      .list-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .router-box {
          width: 295px;
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 20px;
          box-sizing: border-box;
          .name {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: #1d2c67;
            display: flex;
            .arrow {
              width: 14px;
              height: 14px;
              display: inline-block;
              background: url("../../assets/nav/arrow.png") no-repeat;
              background-size: contain;
              margin-left: 4px;
            }
          }
          .desc {
            font-family: "Helvetica Neue";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: rgba(29, 44, 103, 0.4);
            margin-top: 10px;
          }
        }
        .router-box:hover {
          background: linear-gradient(102.53deg, #f1f5ff 9.09%, #ffffff 89.94%);
          .name {
            color: #3d5dcc;
            .arrow {
              background: url("../../assets/nav/arrow_hover.png") no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }
  .solve-mask {
    height: calc(100vh - 370px);
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>