<template>
  <div class="solve-framework wow slideInUp animate__animated_title">
    <div class="solve-framework-title">场景架构</div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tab-menu">
      <el-tab-pane
        :label="item.name"
        :name="item.grade"
        v-for="(item, index) in frameworkArr"
        :key="index"
      >
        <img class="top" :src="item.imgUrl" alt="" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  props: {
    frameworkArr: Array,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
   mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.solve-framework {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 90px 80px;
  &-title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #1d2c67;
    width: 100%;
    text-align: center;
  }
  img {
    width: 1180px;
    height: auto;
  }
  .tab-menu {
    margin-top: 30px;
  }
  :deep().el-tabs__item {
    padding: 0;
    line-height: 80px;
    height: 80px;
    width: 265px;
    text-align: center;
    color: #1d2c67;
    font-size: 16px;
  }
  :deep().el-tabs__item:hover {
    color: #3d5dcc;
  }
  :deep().el-tabs__item.is-active {
    color: #3d5dcc;
  }
  :deep().el-tabs__active-bar {
    background-color: #3d5dcc;
  }
  :deep().el-tabs__header {
    margin: 0 0 40px;
  }
}
</style>