const langUriPrefix = "/:lang "

let namespace = {
    STORE: {},
    PAGE: {
        INDEX: { name: 'index', uri: '/', redirect: '/home' },
        HOME: { name: 'home', uri: '/home' },
        AGRICULTURE: { name: 'agriculture', uri: '/agriculture' },
        COPYRIGHT: { name: 'copyright', uri: '/copyright' },
        DID: { name: 'did', uri: '/did' },
        ENS: { name: 'ens', uri: '/ens' },
        ENTERTAINMENT: { name: 'entertainment', uri: '/entertainment' },
        FINANCE: { name: 'finance', uri: '/finance' },
        GOVERNMENT: { name: 'government', uri: '/government' },
        HEALTHY: { name: 'healthy', uri: '/healthy' },
        INTERNET: { name: 'internet', uri: '/internet' },
        NFT: { name: 'nft', uri: '/nft' },
        TICKETING: { name: 'ticketing', uri: '/ticketing' },
        ABOUT: { name: 'about', uri: '/about' },
        CONTACT: { name: 'contact', uri: '/contact' },
        SEALABC: { name: 'sealabc', uri: '/sealabc' },
        XAAS: { name: 'xaas', uri: '/xaas' },
    }
}


export {
    namespace
}