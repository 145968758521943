<template>
  <div>
    <div class="suspension">
      <div class="box" @click="goUrl('/contact')">
        <img src="@/assets/home/partner.png" alt="" />
        <div>合作咨询</div>
        <div class="silder"></div>
      </div>
      <!-- <div class="box" @click="customerShow = true">
        <img src="@/assets/home/customer.png" alt="" />
        <div>快速解疑</div>
        <div class="silder"></div>
      </div> -->
      <!-- <div class="box">
        <img src="@/assets/home/news.png" alt="" />
        <div>前往论坛</div>
      </div> -->
    </div>
    <Customer v-show="customerShow" @closeDialog="closeDialog"></Customer>
  </div>
</template>

<script>
import Customer from "../../components/commonPopup/Customer.vue";
export default {
  components: {
    Customer,
  },
  data() {
    return {
      customerShow: false,
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
      document.documentElement.scrollTop = 0;
    },
    closeDialog(value) {
      this.customerShow = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.suspension {
  position: fixed;
  bottom: 200px;
  right: 20px;
  z-index: 10;
  .box {
    width: 80px;
    height: 80px;
    background: #3d5dcc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    .silder {
      width: 40px;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      bottom: 0;
    }
    img {
      margin-bottom: 8px;
      width: 26px;
      height: 26px;
    }
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #ffffff;
  }
  .box:hover {
    background: #2e4699;
  }
  .box:nth-child(1) {
    border-radius: 4px 4px 4px 4px;
  }
  .box:nth-child(2) {
    border-radius: 0px 0px 4px 4px;
  }
}
</style>