<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePc :programmeArr="programmeArr"></ProgrammePc>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <Programme :programmeArr="programmeArr"></Programme>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import Programme from "./modules/Programme.vue";
import ProgrammePc from "./modules/ProgrammePc.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    Programme,
    ProgrammePc,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 金融",
        desc: " 通过资产通证映射、价值高效流通、信用体系构建、合约票据电子化等多个方面，降低金融业务风险，减少金融业务中间环节，节省金融成本，实现金融生态闭环，为企业、银行、保险等金融领域中的各方提供底层信用和业务支撑平台，助力金融生态发展。",
        imgUrl: require("@/assets/solution/finance/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 金融",
        desc: "为企业、银行、保险等金融领域中的各方提供底层信用和业务支撑平台，助力金融生态发展。",
        imgUrl: require("@/assets/solution/finance/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "数据与模型方协作困难",
          desc: "数据方在进行风控、定价、营销时，缺乏相应模型来分析、评估用户各维度数据，而模型方，则希望能持续使用外部数据来检验并更新自身风控模型，以提升模型质量",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "信息孤岛",
          desc: "各机构的数据对于本机构来说属于核心资产，数据、模型直接互通牵涉利益和合规风险，实际场景中，各机构数据往往不能出库，难以实现跨机构的数据互通。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "机构治理机制不成熟",
          desc: " 机构缺乏有效的管控机制，时常发生资金挪用、恶意违约等情况，少数问题实际控制人为规避监管要求，刻意构建“隐蔽+分散”结构违规控制金融机构。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/finance/finance-icon-1.png"),
            desc: "释放信用价值 <br />企业征信可靠",
          },
          {
            imgUrl: require("@/assets/solution/finance/finance-icon-2.png"),
            desc: "资金成本降低 <br />投融风险可控",
          },
          {
            imgUrl: require("@/assets/solution/finance/finance-icon-3.png"),
            desc: "合同可信存证 <br />审计可靠高效",
          },
          {
            imgUrl: require("@/assets/solution/finance/finance-icon-4.png"),
            desc: "提升财务效率 <br />数据真实可信",
          },
        ],
        title: "解放企业信用价值，助力金融生态发展。",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/government/supply-chain-finance.png"),
          name: "供应链金融",
          grade: "first",
        },
        {
          imgUrl: require("@/assets/solution/government/contract-bill.png"),
          name: "合同票据",
          grade: "second",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
     if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>