<template>
  <div class="nav-bg-box">
    <div class="nav-bg">
      <nav class="nav-bar">
        <div class="nav-img">
          <img src="@/assets/nav/jmcx_logo.png" alt="" @click="goUrl('/')" />
        </div>
        <img
          class="menu-arrow-down"
          @click="popMenu()"
          src="@/assets/mobile/menu-icon.png"
          v-if="!userClick"
        />
        <img
          class="menu-arrow-down"
          @click="popMenu()"
          src="@/assets//mobile/menu-close.png"
          v-else
        />
        <div :class="userClick ? 'dyVHBn' : 'hdQKxF'" class="nav-title">
          <ul class="switch-menu">
            <li @click="documentMenuDoc()">
              <div class="first-title">
                <span>核心产品</span>
                <img
                  v-show="!documentShowDoc"
                  src="@/assets/nav/menu_arrow.png"
                  alt=""
                />
                <img
                  v-show="documentShowDoc"
                  src="@/assets/nav/menu_up.png"
                  alt=""
                />
              </div>
              <div class="menu" v-show="documentShowDoc">
                <div
                  class="menu-box"
                  v-for="(item, index) in productsArr"
                  :key="index"
                >
                  <div class="menu-box-left">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="menu-box-right" @click="goUrl(item.path)">
                    <div class="title">
                      {{ item.title }}
                    </div>
                    <div class="desc">{{ item.desc }}</div>
                  </div>
                </div>
              </div>
            </li>
            <li @click="documentMenu()">
              <div class="first-title">
                <span>解决方案</span>
                <img
                  v-show="!documentShow"
                  src="@/assets/nav/menu_arrow.png"
                  alt=""
                />
                <img
                  v-show="documentShow"
                  class="drop-menu"
                  src="@/assets/nav/menu_up.png"
                  alt=""
                />
              </div>
              <div class="menu" v-show="documentShow">
                <div
                  class="menu-box"
                  v-for="(item, index) in routerList"
                  :key="index"
                >
                  <div class="menu-box-right" @click="goUrl(item.path)">
                    <div class="title">
                      {{ item.name }}
                    </div>
                    <div class="desc">{{ item.desc }}</div>
                  </div>
                </div>
              </div>
            </li>
            <li @click="goUrl('/about')">
              <div class="link">关于我们</div>
            </li>
            <li @click="goUrl('/contact')">
              <div class="link">合作咨询</div>
            </li>
            <!-- <li @click="toToken">快速解疑</li> -->
            <!-- <li>前往论坛</li> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userClick: false,
      documentShow: false,
      documentShowDoc: false,
      productsArr: [
        {
          img: require("@/assets/nav/xaas.png"),
          title: "XaaS云服务",
          desc: "提供安全灵活的跨链互操作通道",
          path: "/xaas",
        },
        {
          img: require("@/assets/nav/sealabc.png"),
          title: "SealABC",
          desc: "组件化积木式搭建方式",
          path: "/sealabc",
        },
      ],
      routerList: [
        {
          name: "政务",
          desc: "政务公开透明，提高服务效率，精细行政管理。",
          path: "/government",
        },
        {
          name: "金融",
          desc: "完善金融网络，助推数字金融高质量发展。",
          path: "/finance",
        },
        {
          name: "农业",
          desc: "农产品防伪溯源，助力农业生态建设。",
          path: "/agriculture",
        },
        {
          name: "版权",
          desc: "用区块链技术构建更加可信安全的数字版权体系。",
          path: "/copyright",
        },
        {
          name: "医疗健康",
          desc: "提供安全、可靠、隐私的个人医疗大数据平台。",
          path: "/healthy",
        },
        {
          name: "物联网",
          desc: "让IoT网络更安全，有效保护设备和个人隐私。",
          path: "/internet",
        },
        {
          name: "文娱",
          desc: "IP资产数字化，助力文化娱乐IP产业生态发展。",
          path: "/entertainment",
        },
        {
          name: "票务系统",
          desc: "助力解决传统票务低效问题，变革票务行业格局。",
          path: "/ticketing",
        },
        {
          name: "DID身份",
          desc: "打破传统的数字身份存在诸多问题。",
          path: "/did",
        },
        {
          name: "NFT 数字藏品",
          desc: "用户自由定制构，建业务场景。",
          path: "/nft",
        },
        {
          name: "域名服务",
          desc: "打破传统市场，Web 3.0 域名满足用户新需求。",
          path: "/ens",
        },
      ],
    };
  },
  methods: {
    popMenu() {
      this.userClick = !this.userClick;
      this.documentShow = false;
      this.documentShowDoc = false;
    },
    documentMenu() {
      this.documentShow = !this.documentShow;
    },
    documentMenuDoc() {
      this.documentShowDoc = !this.documentShowDoc;
    },
    goUrl(path) {
      document.documentElement.scrollTop = 0;
      this.userClick = false;
      this.$router.push(path);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.nav-bg-box {
  width: 100%;
  height: 56px;
  background-color: #fff;
  z-index: 999;
}
.nav-bg {
  width: 100%;
  height: 56px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .nav-bar {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .nav-img {
      position: absolute;
      top: 12px;
      left: 9px;
      cursor: pointer;
      img {
        vertical-align: bottom;
        width: auto;
        height: 32px;
      }
    }
  }
  .menu-arrow-down {
    position: absolute;
    right: 16px;
    z-index: 9;
    width: 30px;
    height: 30px;
  }
  .nav-title {
    position: absolute;
    left: 0;
    top: 56px;
    background-color: #fff;
    color: #1d2c67;
    line-height: 40px;
    width: 100%;
    height: calc(100vh - 56px);
    z-index: 99999;
    overflow: auto;
    .first-title {
      img {
        width: 16px;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        margin-top: -4px;
      }
    }
  }
  .switch-menu {
    // overflow: hidden;
    // animation: menufirst 0.3s ease-in-out both;
    // -webkit-animation: menufirst 0.3s ease-in-out both;
    // animation-fill-mode: forwards;
    li {
      .first-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      list-style: none;
      text-align: left;
      cursor: pointer;
      text-align: left;
      width: 100%;
      line-height: 64px;
      .link {
        margin: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
@-webkit-keyframes myfirst {
  0% {
    height: 0px;
  }
  100% {
    height: 192px;
  }
}

@-webkit-keyframes myfirstdoc {
  0% {
    height: 0px;
  }
  100% {
    height: 48px;
  }
}
@-webkit-keyframes menufirst {
  0% {
    height: 0px;
  }
  100% {
    height: 492px;
  }
}
.menu {
  background: #f7f7f7;
  padding: 15px 0;
  .menu-box {
    display: flex;
    height: 60px;
    align-items: center;
    margin: 0 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    &-left {
      margin-right: 10px;
      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
    &-right {
      .title {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #1d2c67;
        margin-right: 20px;
        text-align: left;
      }
      .title:hover {
        color: #3d5dcc;
      }
      .desc {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: rgba(29, 44, 103, 0.4);
        margin-top: 8px;
      }
    }
  }
  .menu-box:last-child {
    border-bottom: 0px;
  }
}
.hdQKxF {
  position: fixed;
  inset: 0px;
  z-index: 100;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  overflow: auto;
  transform: translateY(-118%);
}
.dyVHBn {
  position: fixed;
  inset: 64px 0px 0px;
  z-index: 100;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  overflow: auto;
  transform: translateY(0px);
}
</style>