<template>
  <div class="solve">
    <div v-if="!mobile" style="min-width:1280px">
      <BannerPc :bannerArr="bannerArr"></BannerPc>
      <SencePc :senceArr="senceArr"></SencePc>
      <ProgrammePcOther :programmeArr="programmeArr"></ProgrammePcOther>
      <FrameworkPc :frameworkArr="frameworkArr"></FrameworkPc>
    </div>
    <div v-if="mobile">
      <Banner :bannerArrMobile="bannerArrMobile"></Banner>
      <Sence :senceArr="senceArr"></Sence>
      <ProgrammeOther :programmeArr="programmeArr"></ProgrammeOther>
      <Framework :frameworkArr="frameworkArr"></Framework>
    </div>
  </div>
</template>

<script>
import Banner from "./modules/Banner.vue";
import BannerPc from "./modules/BannerPc.vue";
import Sence from "./modules/Sence.vue";
import SencePc from "./modules/SencePc.vue";
import ProgrammeOther from "./modules/ProgrammeOther.vue";
import ProgrammePcOther from "./modules/ProgrammePcOther.vue";
import Framework from "./modules/Framework.vue";
import FrameworkPc from "./modules/FrameworkPc.vue";
import { deviceEnquire } from "../../utils/util";
export default {
  components: {
    Banner,
    BannerPc,
    Sence,
    SencePc,
    ProgrammeOther,
    ProgrammePcOther,
    Framework,
    FrameworkPc,
  },
  data() {
    return {
      mobile: false,
      activeName: "first",
      bannerArr: {
        title: "区块链+ 票务系统",
        desc: " NFT门票属于数字资产，是持有者进入活动的通行凭据。门票持有者可获得惊喜福利，例如见面会、独家周边商品等，享受前所未有的沉浸式粉丝体验。除了与粉丝建立紧密联系之外，NFT门票还能让艺术家、活动组织方和利益相关者持续获得版权费。",
        imgUrl: require("@/assets/solution/ticket/banner.png"),
      },
      bannerArrMobile: {
        title: "区块链+ 票务系统",
        desc: " NFT门票属于数字资产，是持有者进入活动的通行凭据。门票持有者可获得惊喜福利，例如见面会、独家周边商品等，享受前所未有的沉浸式粉丝体验。",
        imgUrl: require("@/assets/solution/ticket/banner.png"),
      },
      senceArr: [
        {
          imgUrl: require("@/assets/solution/01.png"),
          title: "票务发行受管控",
          desc: "受限于监管管控，门票发行渠道较少。二级票务市场的转售商哄抬票价，黄牛票贩则通过网络买下大部分门票并高价转售，对渠道方以及购票用户带来不可估量的价值损失。",
        },
        {
          imgUrl: require("@/assets/solution/02.png"),
          title: "票务伪造",
          desc: "纸质门票及电子票的一大主要缺点在于容易伪造，这给活动现场带来了安全隐患，也让那些不知情买到假票的用户感到万分沮丧。",
        },
        {
          imgUrl: require("@/assets/solution/03.png"),
          title: "票务丢失",
          desc: "传统纸质门票极易丢失或损坏。NFT门票则存储于数字钱包中，通过手机即可随时访问，这将大大降低门票丢失或损坏的风险。",
        },
      ],
      programmeArr: {
        iconArr: [
          {
            imgUrl: require("@/assets/solution/ticket/ticket-icon-1.png"),
            desc: "NFT门票的制作筹备时间要比实体票所需的时间短，组织方可快速完成票务铸造和发行。",
            title: "降低票务成本，加速票务制作",
          },
          {
            imgUrl: require("@/assets/solution/ticket/ticket-icon-2.png"),
            desc: "NFT依托的区块链技术，为所有参与者创造有形价值。通过智能合约技术，艺术家和活动组织方可持续获得NFT门票带来的版权费。",
            title: "舍弃发行渠道，持续创收机会",
          },
          {
            imgUrl: require("@/assets/solution/ticket/ticket-icon-3.png"),
            desc: "NFT门票则存储于票务小程序中，通过手机即可随时访问。",
            title: "无视丢失风险，无需担心损坏",
          },
          {
            imgUrl: require("@/assets/solution/ticket/ticket-icon-4.png"),
            desc: "区块链与物联网结合应用还可确保产品在整个生命周期的质量和正品属性，帮助企业维护自身品牌和声誉。",
            title: "票务上链存储，记录随时可查",
          },
        ],
        title: "助力解决传统票务行业的低效问题，变革票务行业格局",
      },
      frameworkArr: [
        {
          imgUrl: require("@/assets/solution/ticket/ticket.png"),
          name: "NFT 票务",
          grade: "first",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    deviceEnquire((deviceType) => {
      // console.log("deviceType", deviceType);
      // 媒体改变时 将新的状态传给store
      if (deviceType == "mobile") {
        this.mobile = true;
      } else if (deviceType == "tablet") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./modules/solve.scss";
</style>