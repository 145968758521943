<template>
  <div>
    <div class="banner">
      <div class="header-image">
        <img src="@/assets/mobile/banner-bg.png" alt="" />
        <div class="banner-image">
          <img :src="bannerArrMobile.imgUrl" alt="" />
        </div>
      </div>
      <div class="banner-box">
        <div class="banner-title">
          {{ bannerArrMobile.title }}
        </div>
        <div class="banner-desc">
          {{ bannerArrMobile.desc }}
        </div>
        <div class="contact-btn" @click="goUrl('/contact')">联系我们</div>
      </div>
    </div>
    <div class="solve-title">
      <div class="header-image">
        <img src="@/assets/home/Rectangle 60 (2).png" alt="" />
        <div class="right">
          <img :src="bannerArrMobile.imgUrl" alt="" />
        </div>
      </div>
      <div class="banner">
        <div class="banner-box">
          <div class="left">
            <div class="title">
              {{ bannerArrMobile.title }}
            </div>
            <div class="content">
              {{ bannerArrMobile.desc }}
            </div>
            <div style="display: flex">
              <Contactus></Contactus>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contactus from "./Contactus.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Contactus,
  },
  props: {
    bannerArrMobile: Object,
    required: true,
  },
  data() {
    return {};
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/transfer.scss";
.banner {
  display: block;
  width: 100%;
  height: 480px;
  background: #f5f7fc;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  .header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 480px;
    img {
      width: 100%;
      height: 345px;
    }
    .banner-image {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 15px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .banner-box {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    box-sizing: border-box;
    font-family: "Helvetica Neue";
    font-style: normal;
    .banner-title {
      font-weight: 700;
      font-size: 23px;
      line-height: 100%;
      text-align: center;
      width: 100%;
      color: #1d2c67;
      margin-top: 40px;
    }
    .banner-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      /* or 45px */
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(29, 44, 103, 0.8);
      margin-top: 20px;
    }
    .contact-btn {
      width: 100%;
      height: 45px;
      background: #3d5dcc;
      border-radius: 2px;
      line-height: 45px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #e8eaf4;
      margin-top: 20px;
    }
  }
}
.solve-title {
  display: none;
}
@media screen and (min-width: 445px) and (max-width: 1180px) {
  .banner {
    display: none;
  }
  .solve-title {
    display: block;
    width: 100%;
    height: 268px;
    background: rgba(245, 247, 252, 0.4);
    position: relative;
    .header-image {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100vw - 25%);
      z-index: 0;
      height: 268px;
      img {
        width: 100%;
        height: 268px;
      }
      .right {
        position: absolute;
        top: 0;
        right: -25%;
        width: 50%;
        display: flex;
        align-items: center;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .banner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: calc(100vw - 25%);
      height: 268px;
      z-index: 2;
      background: transparent;
    }
    .banner-box {
      width: calc(100vw - 25%);
      height: 268px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        width: 100%;
        .title {
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 25px;
          color: #1d2c67;
        }
        .content {
          width: 75%;
          font-family: "Helvetica Neue";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 160%;
          color: rgba(29, 44, 103, 0.8);
          margin-top: 20px;
        }
      }
    }
  }
}
</style>