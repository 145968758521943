import { routeFromNamespace } from "./tools";
import { namespace } from "../../js/namespace";

import Index from "../../views/index/Index"
import Home from "../../views/home/Home"
import Agriculture from "../../views/solution/Agriculture"
import Copyright from "../../views/solution/Copyright"
import DID from "../../views/solution/DID"
import ENS from "../../views/solution/ENS"
import Entertainment from "../../views/solution/Entertainment"
import Finance from "../../views/solution/Finance"
import Government from "../../views/solution/Government"
import Healthy from "../../views/solution/Healthy"
import Internet from "../../views/solution/Internet"
import NFT from "../../views/solution/NFT"
import Ticketing from "../../views/solution/Ticketing"
import About from "../../views/about/About"
import Contact from "../../views/about/Contact"
import Sealabc from "../../views/core/Sealabc"
import Xaas from "../../views/core/Xaas"


const hasLanUri = false
let arr = [].concat([
    routeFromNamespace(namespace.PAGE.INDEX, Index, hasLanUri),
])

arr[0].children = [].concat([
    routeFromNamespace(namespace.PAGE.HOME, Home, hasLanUri),
    routeFromNamespace(namespace.PAGE.AGRICULTURE, Agriculture, hasLanUri),
    routeFromNamespace(namespace.PAGE.COPYRIGHT, Copyright, hasLanUri),
    routeFromNamespace(namespace.PAGE.DID, DID, hasLanUri),
    routeFromNamespace(namespace.PAGE.ENS, ENS, hasLanUri),
    routeFromNamespace(namespace.PAGE.ENTERTAINMENT, Entertainment, hasLanUri),
    routeFromNamespace(namespace.PAGE.FINANCE, Finance, hasLanUri),
    routeFromNamespace(namespace.PAGE.GOVERNMENT, Government, hasLanUri),
    routeFromNamespace(namespace.PAGE.HEALTHY, Healthy, hasLanUri),
    routeFromNamespace(namespace.PAGE.INTERNET, Internet, hasLanUri),
    routeFromNamespace(namespace.PAGE.NFT, NFT, hasLanUri),
    routeFromNamespace(namespace.PAGE.TICKETING, Ticketing, hasLanUri),
    routeFromNamespace(namespace.PAGE.ABOUT, About, hasLanUri),
    routeFromNamespace(namespace.PAGE.CONTACT, Contact, hasLanUri),
    routeFromNamespace(namespace.PAGE.SEALABC, Sealabc, hasLanUri),
    routeFromNamespace(namespace.PAGE.XAAS, Xaas, hasLanUri),
])

export default arr