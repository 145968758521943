<template>
  <div class="nav-box">
    <div class="nav">
      <div class="nav-menu">
        <div class="logo">
          <img src="@/assets/nav/jmcx_logo.png" alt="" @click="go('/')" />
        </div>
        <div class="nav-list">
          <ul>
            <li @click="go('/')">首页</li>
            <li
              @mouseenter="moveProducts"
              @mouseleave="moveProducts"
              style="position: relative"
            >
              核心产品
              <Products
                v-if="showProducts"
                @closeProducts="closeProducts"
              ></Products>
            </li>
            <li @mouseenter="moveSolve" @mouseleave="moveSolve">
              解决方案
              <Solve v-if="showSolve" @closeSlove="closeSlove"></Solve>
            </li>
            <li @click="go('/about')">关于我们</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Products from "../commonPopup/Products.vue";
import Solve from "../commonPopup/Solve.vue";
export default {
  components: {
    Products,
    Solve,
  },
  data() {
    return {
      showProducts: false,
      showSolve: false,
    };
  },
  methods: {
    go(routerName) {
      this.$router.push(routerName);
      document.documentElement.scrollTop = 0;
    },
    moveProducts() {
      this.showProducts = !this.showProducts;
    },
    moveSolve() {
      this.showSolve = !this.showSolve;
    },
    closeSlove(value) {
      this.showSolve = value;
    },
    closeProducts(value) {
      this.showProducts = value;
    },
  },
  mounted() {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.nav-box {
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 99;
}
.nav {
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.nav-menu {
  width: 1180px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  img {
    margin-right: 308px;
  }
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 80px;
      height: 30px;
    }
  }
}
.nav-list {
  ul {
    display: flex;
  }
  ul > li {
    list-style: none;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 80px;
    color: #1d2c67;
    cursor: pointer;
    height: 80px;
    padding: 0 30px;
  }
  ul > li:hover {
    color: #3d5dcc;
  }
}
</style>
